import { useState } from 'react'
import { useStateValue } from '../state-provider/StateProvider'
import '../cart-mobile/CartMobile.css'
import { asset } from '../../utils/utils'
import CircularProgress from '@material-ui/core/CircularProgress'
import { removeFromCart } from './cart-mobile-row.action'

const CartMobileRow = ({ product }) => {
  const [{ cardUuid, sequencePollUuid, api_url }, dispatch] = useStateValue()

  const [isLoading, setIsLoading] = useState(false)
  function removeProduct() {
    if (isLoading) return
    setIsLoading(true)

    fetch(
      api_url + '/sequencepolls/' + sequencePollUuid + '/sessionParameter',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: 'REMOVE_ITEM_FROM_CART',
          value: cardUuid,
        }),
      },
    )
      .then(res => res.json())
      .then(async result => {
        setIsLoading(false)
        dispatch(removeFromCart({ variantId: product.variantId }))
      })
  }
  return (
    <>
      <div className="cart-product">
        <img src={product.img_url} className="variant-img" alt="default"></img>
        <div className="desc">
          <div className="name-desc">
            <div className="name">{product.name}</div>
            <div className="variant">
              {product.size}, {product.color}
            </div>
          </div>
          <div className="price">
            {product.price.amount} {product.price.currency}
          </div>
        </div>
        <div className="remove-icon" onClick={removeProduct}>
          {isLoading ? (
            <CircularProgress size={'30px'} style={{ color: 'black' }} />
          ) : (
            <img src={asset('./remove-icon.svg')} alt="default"></img>
          )}
        </div>
      </div>
    </>
  )
}

export default CartMobileRow
