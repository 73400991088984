import React, { useState, useEffect, useCallback } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { useStateValue } from '../state-provider/StateProvider'
import { setProducts, slide } from '../app/App.action'
import { setScrollsnaps, setIndexScroll } from './slider.action'

import './Slider.css'

function Slider({ items, showArtifacts, setShowArtifacts }) {
  const [
    { finalCardUuid, slideTo, indexScroll, api_url, sequencePollUuid },
    dispatch,
  ] = useStateValue()
  const [cardIndex, setCardIndex] = useState(0)

  function fetchData(index) {
    //get card id selected by slider:
    if (items[index]) {
      let fetchUrl =
        api_url +
        '/sequencepolls/' +
        sequencePollUuid +
        '?cardId=' +
        items[index]?.cardId
      fetch(fetchUrl)
        .then(res => res.json())
        .then(async result => {
          dispatch(setProducts({ products: result.products }))
          dispatch({
            type: 'SET_OVERLAY_CARDS',
            overlayCards: result.overlayCards,
          })
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

  useEffect(() => {
    setCardIndex(items?.findIndex(i => i.cardId === finalCardUuid))
  }, [items, finalCardUuid])

  const [viewportRef, embla] = useEmblaCarousel({
    startIndex: cardIndex,
    loop: true,
  })
  const [, setPrevBtnEnabled] = useState(false)
  const [, setNextBtnEnabled] = useState(false)

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [embla])

  useEffect(() => {
    scrollTo(indexScroll)
  }, [indexScroll, scrollTo])

  useEffect(() => {
    switch (slideTo) {
      case 'next':
        scrollNext()
        return
      case 'prev':
        scrollPrev()
        return
      default:
        return
    }
  }, [slideTo, scrollNext, scrollPrev])
  const onSelect = useCallback(() => {
    if (!embla) return
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
    dispatch(
      setIndexScroll({
        indexScroll: embla.selectedScrollSnap(),
      }),
    )
    dispatch(slide({ to: '' }))
    if (embla.previousScrollSnap() !== null) {
      setShowArtifacts(false)
    } else {
      setShowArtifacts(true)
    }
    //fetch product data
    fetchData(embla.selectedScrollSnap())
  }, [embla, dispatch, setShowArtifacts]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!embla) return
    onSelect()
    dispatch(setScrollsnaps({ scrollSnaps: embla.scrollSnapList() }))
    dispatch(setIndexScroll({ indexScroll: cardIndex }))

    embla.on('select', onSelect)
  }, [embla, dispatch, onSelect, cardIndex])

  return (
    <div className="embla">
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          {items?.map((i, index) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__inner">
                <img
                  className={
                    'embla__slide__img ' + (!showArtifacts ? 'zoomIn' : '')
                  }
                  src={i.image.src}
                  alt="Carousel Slide"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Slider
