import classnames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { isMobile, useMobileOrientation } from 'react-device-detect'
import { Player } from 'video-react'
import WHEPClient from './WHEPClient.js'

import 'video-react/dist/video-react.css'
import FooterLogo from '../footer-logo/FooterLogo'
import PhotoGrid from '../photo-grid/PhotoGrid'
import { useStateValue } from '../state-provider/StateProvider'
import TopBar from '../top-bar/TopBar'
import './Content.css'

import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded'
import ControlBar from 'video-react/lib/components/control-bar/ControlBar'
import PlayToggle from 'video-react/lib/components/control-bar/PlayToggle'
import FullscreenToggle from 'video-react/lib/components/control-bar/FullscreenToggle'
import CurrentTimeDisplay from 'video-react/lib/components/time-controls/CurrentTimeDisplay'
import DurationDisplay from 'video-react/lib/components/time-controls/DurationDisplay'
import TimeDivider from 'video-react/lib/components/time-controls/TimeDivider'

import { useHistory, useParams } from 'react-router-dom'
import BigPlayButton from 'video-react/lib/components/BigPlayButton'
import ProgressControl from 'video-react/lib/components/control-bar/ProgressControl'
import useEventListener from '../../hooks/useEventListener'
import { asset, formatTime } from '../../utils/utils'
import {
  setFinalCard,
  setIsLoading,
  setIsSetVideoCurrentTime,
  setIsWidgetMaximized,
  setCurrentCard,
  setVideoCurrentTime,
  slide,
} from '../app/App.action'
import { setCartDrawer } from './Content.action'

import {
  GA_BackButton,
  GA_MuteVideo,
  GA_PauseVideo,
  GA_PlayVideo,
  GA_UnmuteVideo,
} from '../../events/ga'
import {
  loadVideoTime,
  localStorageAppendToTimestamps,
} from '../../utils/localStorage'
import { EventInteractionType, MetricsType, ws } from '../../events/ws'
import Hls from 'hls.js'

let HeaderType = 'Video' | 'Image' | 'LiveStream'

function Content({ prevVideoHeading, setPrevVideoHeading }) {
  const [
    {
      title,
      cardUuid,
      finalCardUuid,
      header,
      showCartDrawer,
      items,
      sequencePollUuid,
      prevVideoCurrentTime,
      isSetVideoCurrentTime,
      enableCarousel,
      isReviewMode,
      parentCardId,
      isWidgetMaximized,
      isWidgetEmbedded,
      widgetLocation,
      titleStyle,
      isLoading,
      statistics,
      liveData,
    },
    dispatch,
  ] = useStateValue()
  const playerRef = useRef(null)
  const [volume, setVolume] = useState(1)
  const [livestreamVolume, setLivestreamVolume] = useState(1)
  const [isInitial, setIsInitial] = useState(true)
  const [isInitialLivestream, setIsInitialLivestream] = useState(true)
  const [isPaused, setIsPaused] = useState(false)
  const [isEmbedded, setIsEmbedded] = useState(false)
  const [isLikeBtnDisabled, disableLikeBtn] = useState(false)
  const [isLikeClicked, clickLike] = useState(false)
  const [livestreamSeekTime, setLivestreamSeekTime] = useState(false)
  if (header?.videoUrl) {
    ws.registerMetrics(MetricsType.videoPlaying, () => {
      return playerRef?.current?.video?.video?.paused === false
    })
    ws.registerMetrics(MetricsType.videoPaused, () => {
      return playerRef?.current?.video?.video?.paused === true
    })
    ws.registerMetrics(MetricsType.videoMuted, () => {
      return volume === 0.0
    })
    ws.registerMetrics(MetricsType.videoUnmuted, () => {
      return volume !== 0.0
    })
  }

  const handleVolumeChange = vol => {
    // save initial livestream value from local storage
    if (vol !== 1 && vol !== 0) {
      setLivestreamVolume(vol)
    }
    if (isInitialLivestream && liveStreamConnected) {
      if (playerRef.current.video.video.volume == 1 && livestreamVolume != 1) {
        playerRef.current.video.video.volume = livestreamVolume
        setVolume(livestreamVolume)
        return
      }
      setIsInitialLivestream(false)
    }
    setVolume(vol)
  }

  let likeCount = () => {
    if (liveData && liveData.cards && liveData.cards[cardUuid]) {
      return liveData.cards[cardUuid].totalLikes
    }
    return statistics.totalSocialLikes
  }

  function blockRightClick(event) {
    event.preventDefault()
  }
  const cartDrawerRef = useRef(null)
  let history = useHistory()
  const { isLandscape } = useMobileOrientation()
  const landscapeMode = isLandscape && isMobile

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        cartDrawerRef.current &&
        !cartDrawerRef.current?.contains(event.target) &&
        showCartDrawer
      ) {
        dispatch(setCartDrawer({ showCartDrawer: false }))
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [cartDrawerRef, dispatch, showCartDrawer])

  useEventListener('contextmenu', blockRightClick, playerRef?.current)

  useEffect(() => {
    if (finalCardUuid) {
      if (playerRef?.current) playerRef?.current?.pause()
    } else {
      try {
        const currentUrl = new URL(header?.videoUrl || '')?.pathname
        const videoUrl = new URL(
          playerRef?.current?.video?.video?.currentSrc || '',
        )?.pathname
        if (prevVideoHeading !== currentUrl || currentUrl !== videoUrl) {
          setPrevVideoHeading(videoUrl)
          playerRef?.current?.load()
        } else {
          setPrevVideoHeading(videoUrl)
          playerRef?.current?.play()
        }
      } catch (err) {}
    }
  }, [header, finalCardUuid, playerRef])

  useEffect(() => {
    if (liveData && liveData.cards && liveData.cards[cardUuid]) {
      clickLike(liveData.cards[cardUuid].liked === true)
    }
  }, [liveData, cardUuid])

  useEffect(() => {
    const timer = setInterval(() => {
      // continuously append timestamps
      localStorageAppendToTimestamps(cardUuid)
    }, 1000)
    return () => clearInterval(timer)
  }, [cardUuid])

  function autoplayFirstCard() {
    let cardUuid = items[0].cardId

    dispatch(setIsLoading({ isLoading: true }))
    if (!finalCardUuid) {
      history.push('/' + sequencePollUuid + '/' + cardUuid)
      dispatch(setCurrentCard({ cardUuid: cardUuid }))
    }
  }

  function Slide(to) {
    dispatch(slide({ to: to }))
  }

  function goBack() {
    GA_BackButton()
    dispatch(setIsLoading({ isLoading: false }))
    dispatch(setFinalCard({ cardUuid: null }))
    dispatch(setIsSetVideoCurrentTime({ isSetVideoCurrentTime: false }))
    let targetWindow = window.parent
    localStorageAppendToTimestamps(cardUuid)
    targetWindow?.postMessage(
      {
        prevUrl:
          '/' + sequencePollUuid + '/' + (parentCardId ? parentCardId : ''),
        type: 'contester-step',
      },
      '*',
    )
    history.push(
      '/' +
        sequencePollUuid +
        '/' +
        (parentCardId ? parentCardId : '') +
        window.location.search,
    )
  }

  const handleMessage = event => {
    if (event?.data?.type === 'embedded') {
      setIsEmbedded(true)
    }
    if (event?.data?.type === 'volume') {
      if (event.data.isPaused) {
        setIsPaused(true)
      }
      if (event?.data?.volume > 0) {
        if (playerRef.current?.video?.video?.volume !== undefined) {
          playerRef.current.video.video.volume = event?.data?.volume
        }
      }
      handleVolumeChange(event?.data?.volume)
      if (event?.data.isMuted) {
        setPlayerVolume(0)
      } else {
        setPlayerVolume(event?.data?.volume)
      }

      if (event?.data?.muted === true) {
        GA_MuteVideo()
        if (event?.data?.reportEvent)
          ws.sendEvent(EventInteractionType.HEADER_VIDEO_MUTED)
      } else {
        if (!event.data.isPaused) {
          GA_UnmuteVideo()
          if (event?.data?.reportEvent)
            ws.sendEvent(EventInteractionType.HEADER_VIDEO_UNMUTED)
          try {
            playerRef?.current?.play()
          } catch (e) {
            // NotAllowedError
            // The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
          }
        }
      }
    }
    if (event?.data?.type === 'widget-maximized') {
      if (event?.data?.reportEvent) {
        const type =
          event?.data?.status === true
            ? EventInteractionType.WIDGET_MAXIMIZED
            : EventInteractionType.WIDGET_MINIMIZED
        ws.sendEvent(type)
      }
      dispatch(
        setIsWidgetMaximized({
          isWidgetMaximized: event?.data?.status,
        }),
      )
    }
    if (event?.data?.type === 'qa-st') {
      let targetWindow = window.parent
      targetWindow?.postMessage(
        { type: 'volume-status', status: playerRef?.current?.muted || volume },
        '*',
      )
    }
    if (event?.data?.type === 'save-time') {
    }
  }

  const fullscreenScreenChange = type => {
    if (type === 'MAXIMIZED') {
      ws.sendEvent(EventInteractionType.HEADER_VIDEO_MAXIMIZED)
    } else {
      ws.sendEvent(EventInteractionType.HEADER_VIDEO_MINIMIZED)
    }
  }

  const leavingSite = () => {}

  useEffect(() => {
    window.addEventListener('message', handleMessage, true)
    return () => {
      window.removeEventListener('message', handleMessage, true)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', leavingSite)
    return () => {
      window.removeEventListener('beforeunload', leavingSite)
    }
  }, [])

  useEffect(() => {
    addObserver()
  }, [isWidgetMaximized])

  const addObserver = () => {
    const control = document.querySelector('.video-react-fullscreen-control')
    if (control) {
      const options = {
        attributes: true,
      }

      function callback(mutationList, observer) {
        mutationList.forEach(function (mutation) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'class'
          ) {
            if (mutation?.target?.className?.includes('fullscreen-exit')) {
              fullscreenScreenChange('MAXIMIZED')
            } else {
              fullscreenScreenChange('MINIMIZED')
            }
          }
        })
      }

      const observer = new MutationObserver(callback)
      observer.observe(control, options)
    }
  }

  const handleVideoVolumeStatus = () => {
    if (isPaused) {
      playerRef.current?.video?.video?.pause()
    }
    if (isInitial) {
      const video = playerRef?.current?.video?.video
      if (video.paused) {
        let tempVolume = volume
        let promise
        try {
          promise = video?.play()
        } catch (e) {
          // NotAllowedError
          // The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
        }
        promise
          ?.then(res => {
            handleVolumeChange(tempVolume)

            if (video.paused) {
              tempVolume = 0
              handleVolumeChange(tempVolume)
              try {
                video?.play()
              } catch (e) {
                // NotAllowedError
                // The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
              }
            }
            window?.parent?.postMessage(
              { type: 'volume-status', tempVolume },
              '*',
            )
          })
          .catch(err => {
            window?.parent?.postMessage({ type: 'volume-status', volume }, '*')
            handleVolumeChange(0)
            setPlayerVolume(0)
            try {
              video?.play()
            } catch (e) {
              // NotAllowedError
              // The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.
            }
          })
      } else {
        setPlayerVolume(volume)
        window?.parent?.postMessage({ type: 'volume-status', volume }, '*')
      }
    } else {
      window?.parent?.postMessage({ type: 'volume-status', volume }, '*')
    }
  }

  const setPlayerVolume = volume => {
    if (playerRef?.current?.video?.video?.volume !== undefined) {
      if (volume === 0 && playerRef.current.video?.video?.muted !== undefined) {
        playerRef.current.video.video.muted = true
        return
      }

      if (volume > 0) {
        if (
          playerRef.current?.video?.video?.muted !== undefined &&
          playerRef.current?.video?.video !== undefined
        ) {
          playerRef.current.video.video.muted = false
          playerRef.current.video.video.volume = volume
        }
      }
    }
  }

  useEffect(() => {
    loadVideoTime(cardUuid)
    let targetWindow = window.parent
    targetWindow?.postMessage({ type: 'q-volume' }, '*')
  }, [playerRef.current])

  useEffect(() => {
    if (liveStreamConnected) {
      playerRef.current.video.video.play()
    }
  }, [liveStreamConnected])

  useEffect(() => {
    let targetWindow = window.parent
    targetWindow?.postMessage(
      {
        type: 'has-video-res',
        status: header?.videoUrl || header?.liveStreamUrl ? true : false,
      },
      '*',
    )
  }, [header])

  // hardcoded value for possibility to control highlighting from contester panel
  const allowHighlight = false

  const handleUnmuteReminder = () => {
    if (playerRef.current?.video?.video?.muted !== undefined) {
      playerRef.current.video.video.muted = false
    }
  }

  let headerType
  if (header?.liveStreamUrl) {
    headerType = 'LiveStream'
  } else if (header?.videoUrl) {
    headerType = 'Video'
  } else if (header?.imageUrl) {
    headerType = 'Image'
  } else {
    headerType = 'Title'
  }

  let liveStreamUrl = null
  const [liveStreamConnected, setLiveStreamConnected] = useState(false)

  const liveStreamConnectedCallback = () => {
    setLiveStreamConnected(true)
  }

  useEffect(() => {
    if (
      header?.liveStreamUrl &&
      liveStreamUrl !== header?.liveStreamUrl &&
      playerRef.current.video.video !== undefined
    ) {
      setLiveStreamConnected(false)
      liveStreamUrl = header?.liveStreamUrl
      if (liveStreamUrl.indexOf('webRTC') > -1) {
        // webRTC
        const test = new WHEPClient(
          liveStreamUrl,
          playerRef.current.video.video,
          liveStreamConnectedCallback,
        )
        playerRef?.current?.subscribeToStateChange((state, prevState) => {
          const prev = Math.floor(prevState.currentTime)
          const current = Math.floor(state.currentTime)
          if (
            current > prev &&
            livestreamSeekTime != current &&
            current != 0 &&
            (current - prev == 1 || current - prev == 2)
          ) {
            setLivestreamSeekTime(current)
          }
        })
      } else {
        // HLS
        if (!Hls.isSupported()) {
          liveStreamConnectedCallback()
        } else {
          let hls = new Hls()
          hls.attachMedia(playerRef.current.video.video)
          hls.on(Hls.Events.MEDIA_ATTACHED, () => {
            hls.loadSource(liveStreamUrl)
          })
          playerRef.current.video.video.play()
          liveStreamConnectedCallback()
          playerRef?.current?.subscribeToStateChange((state, prevState) => {
            const prev = Math.floor(prevState.currentTime)
            const current = Math.floor(state.currentTime)
            if (
              current > prev &&
              livestreamSeekTime != current &&
              current != 0 &&
              (current - prev == 1 || current - prev == 2)
            ) {
              setLivestreamSeekTime(current)
            }
          })
        }
      }
    }
  }, [header?.liveStreamUrl])

  const handleShareButton = () => {
    if (navigator.share) {
      ws.sendEvent(EventInteractionType.SOCIAL_SHARE)
      navigator
        .share({
          url: widgetLocation ?? window.location.href,
        })
        .then(() => {
          console.log('Sharing successful')
        })
        .catch(() => {
          console.log('Sharing failed')
        })
    }
  }

  const handleLikeClick = () => {
    ws.sendEvent(EventInteractionType.SOCIAL_LIKE)
    clickLike(true)
    disableLikeBtn(true)
    setTimeout(() => {
      disableLikeBtn(false)
    }, 700)
  }

  const onPauseChangeHandler = value => {
    let targetWindow = window.parent
    targetWindow?.postMessage({ type: 'pause-change', status: value }, '*')
  }

  return (
    <>
      <TopBar />
      <div
        className={classnames('content', {
          'has-image': headerType === 'Image',
          'has-title': headerType === 'Title',
        })}
      >
        <div className={classnames('photo-container', {})}>
          <header
            onContextMenu={e => e.preventDefault()}
            className={finalCardUuid || header === null ? 'hide' : 'show'}
          >
            {headerType === 'LiveStream' && (
              <>
                <Player
                  onContextMenu={e => e.preventDefault()}
                  loop={true}
                  ref={playerRef}
                  playsInline
                  muted={true}
                  autoPlay={true}
                  width="100%"
                  height="100%"
                  style={{ display: liveStreamConnected ? 'block' : 'none' }}
                  onVolumeChange={e => {
                    let tempVol = e?.target?.volume
                    if (e?.target?.muted) {
                      tempVol = 0
                    }
                    if (e?.target?.muted) {
                      GA_MuteVideo()
                    }
                    if (isWidgetMaximized) {
                      if (volume === 0 && tempVol !== 0) {
                        ws.sendEvent(EventInteractionType.HEADER_VIDEO_UNMUTED)
                      }
                      if (volume !== 0 && tempVol === 0) {
                        ws.sendEvent(EventInteractionType.HEADER_VIDEO_MUTED)
                      }
                    }
                    window?.parent?.postMessage(
                      { type: 'volume-status', volume: tempVol },
                      '*',
                    )
                    setPlayerVolume(tempVol)
                    handleVolumeChange(tempVol)
                  }}
                  fluid={false}
                  poster={asset('poster.svg')}
                  onPlay={e => {
                    onPauseChangeHandler(false)
                    GA_PlayVideo()
                    // If widget is maximized it means we triggered click from here
                    // Else widget is not maximized it means we automatically played video when user unmuted widget
                    if (!isInitial && isWidgetMaximized && !isPaused) {
                      ws.sendEvent(EventInteractionType.HEADER_VIDEO_UNPAUSED)
                    } else {
                      setIsInitial(false)
                    }
                  }}
                  onPause={() => {
                    onPauseChangeHandler(true)
                    GA_PauseVideo()
                    ws.sendEvent(EventInteractionType.HEADER_VIDEO_PAUSED)
                  }}
                >
                  <BigPlayButton disabled />
                  <ControlBar disabled={isWidgetEmbedded && !isWidgetMaximized}>
                    <CurrentTimeDisplay disabled />
                    <TimeDivider disabled />
                    <PlayToggle disabled />
                    <DurationDisplay disabled />
                    <FullscreenToggle disabled />
                    <ProgressControl
                      className={'livestream-tooltip'}
                      player={{ duration: 1, currentTime: 1, seekingTime: 1 }}
                      disabled={isWidgetEmbedded && !isWidgetMaximized}
                    />
                  </ControlBar>
                  {isWidgetMaximized && (
                    <>
                      <div className="livestream-indicator">
                        {formatTime(livestreamSeekTime)}
                        <span className="live-dot"></span>
                        LIVE
                      </div>
                      <div className="sequence-poll-controls">
                        <div className="sequence-poll-control">
                          <img
                            onClick={() => handleShareButton()}
                            src={asset('icon-share.svg')}
                          />
                        </div>
                        <div className="sequence-poll-control">
                          <div className="control-label">{likeCount()}</div>
                          <div className="control-container">
                            <button
                              disabled={isLikeBtnDisabled}
                              className="like-button"
                            >
                              <span onClick={() => handleLikeClick()}>
                                <img
                                  src={asset(
                                    `${
                                      isLikeClicked
                                        ? 'icon-like-full.svg'
                                        : 'icon-like.svg'
                                    }`,
                                  )}
                                />
                              </span>
                            </button>
                          </div>
                        </div>
                        <div className="sequence-poll-control view-only">
                          <div className="control-label">
                            {statistics.totalVideoViews}
                          </div>
                          <div className="control-container">
                            <img src={asset('icon-view.svg')} />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Player>
                {!volume && isWidgetMaximized && liveStreamConnected ? (
                  <button
                    className="unmute-button"
                    onClick={() => handleUnmuteReminder()}
                  >
                    Click to unmute{' '}
                    <img src={asset('unmute.svg')} className="unmute" />
                  </button>
                ) : null}
                {!liveStreamConnected && (
                  <img
                    style={{ position: 'absolute', objectFit: 'none' }}
                    src={asset('stream-loading-1.gif')}
                  />
                )}
              </>
            )}

            {headerType === 'Video' && (
              <>
                <Player
                  onContextMenu={e => e.preventDefault()}
                  loop={true}
                  ref={playerRef}
                  playsInline
                  autoPlay={true}
                  width="100%"
                  height="100%"
                  onVolumeChange={e => {
                    let tempVol = e?.target?.volume
                    if (e?.target?.muted) {
                      tempVol = 0
                    }
                    if (e?.target?.muted) {
                      GA_MuteVideo()
                    }
                    if (isWidgetMaximized) {
                      if (volume === 0 && tempVol !== 0) {
                        ws.sendEvent(EventInteractionType.HEADER_VIDEO_UNMUTED)
                      }
                      if (volume !== 0 && tempVol === 0) {
                        ws.sendEvent(EventInteractionType.HEADER_VIDEO_MUTED)
                      }
                    }
                    window?.parent?.postMessage(
                      { type: 'volume-status', volume: tempVol },
                      '*',
                    )
                    setPlayerVolume(tempVol)
                    handleVolumeChange(tempVol)
                  }}
                  fluid={false}
                  poster={asset('poster.svg')}
                  onLoadedData={() => {
                    handleVideoVolumeStatus()
                    loadVideoTime(cardUuid)
                  }}
                  onPlay={e => {
                    onPauseChangeHandler(false)
                    GA_PlayVideo()
                    // If widget is maximized it means we triggered click from here
                    // Else widget is not maximized it means we automatically played video when user unmuted widget
                    if (!isInitial && isWidgetMaximized && !isPaused) {
                      ws.sendEvent(EventInteractionType.HEADER_VIDEO_UNPAUSED)
                    } else {
                      setIsInitial(false)
                    }
                  }}
                  onPause={() => {
                    onPauseChangeHandler(true)
                    GA_PauseVideo()
                    ws.sendEvent(EventInteractionType.HEADER_VIDEO_PAUSED)
                    if (!isSetVideoCurrentTime) {
                      dispatch(
                        setVideoCurrentTime({
                          prevVideoCurrentTime:
                            document.querySelector('.video-react-video')
                              ?.currentTime,
                        }),
                      )
                      dispatch(
                        setIsSetVideoCurrentTime({
                          isSetVideoCurrentTime: true,
                        }),
                      )
                    }
                  }}
                  startTime={isSetVideoCurrentTime ? 0 : prevVideoCurrentTime}
                >
                  <source src={header?.videoUrl} />
                  <BigPlayButton disabled />
                  <ControlBar disabled={isWidgetEmbedded && !isWidgetMaximized}>
                    <CurrentTimeDisplay disabled />
                    <TimeDivider disabled />
                    <PlayToggle disabled />
                    <DurationDisplay disabled />
                    <ProgressControl
                      disabled={isWidgetEmbedded && !isWidgetMaximized}
                    />
                  </ControlBar>
                  {isWidgetMaximized && (
                    <div className="sequence-poll-controls">
                      <div className="sequence-poll-control">
                        <img
                          onClick={() => handleShareButton()}
                          src={asset('icon-share.svg')}
                        />
                      </div>
                      <div className="sequence-poll-control">
                        <div className="control-label">{likeCount()}</div>
                        <div className="control-container">
                          <button
                            disabled={isLikeBtnDisabled}
                            className="like-button"
                          >
                            <span onClick={() => handleLikeClick()}>
                              <img
                                src={asset(
                                  `${
                                    isLikeClicked
                                      ? 'icon-like-full.svg'
                                      : 'icon-like.svg'
                                  }`,
                                )}
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="sequence-poll-control view-only">
                        <div className="control-label">
                          {statistics.totalVideoViews}
                        </div>
                        <div className="control-container">
                          <img src={asset('icon-view.svg')} />
                        </div>
                      </div>
                    </div>
                  )}
                </Player>
                {!volume && isWidgetMaximized ? (
                  <button
                    className="unmute-button"
                    onClick={() => handleUnmuteReminder()}
                  >
                    Click to unmute{' '}
                    <img src={asset('unmute.svg')} className="unmute" />
                  </button>
                ) : null}
              </>
            )}

            {headerType === 'Image' && (
              <img alt="Poster" src={header?.imageUrl} />
            )}
            {header?.title ? (
              <div
                style={{
                  ...titleStyle,
                  display: 'flex',
                  width: '100%',
                  color: titleStyle?.color,
                  fontWeight: titleStyle?.fontWeight,
                  fontStyle: titleStyle?.fontStyle,
                  fontFamily: titleStyle?.fontFamily,
                  justifyContent:
                    titleStyle?.aligment === 'right'
                      ? 'end'
                      : titleStyle?.aligment === 'left'
                      ? 'start'
                      : 'center',
                }}
              >
                {title?.split('|').length > 1 ? (
                  <>
                    <h2 className="title">{title?.split('|')[0]}</h2>
                    <h3 className="subtitle">{title?.split('|')[1]}</h3>
                  </>
                ) : (
                  <h3 className="title">{title?.split('|')[0]}</h3>
                )}
              </div>
            ) : null}
          </header>
          <div className="carousel-nav">
            <div className="spacer-y" />
            {enableCarousel && !isLoading && (
              <button
                disabled={!(enableCarousel && finalCardUuid)}
                onClick={() => Slide('prev')}
                style={{
                  cursor: !(enableCarousel && finalCardUuid)
                    ? 'normal'
                    : 'pointer',
                  opacity: !(enableCarousel && finalCardUuid) ? '0' : '1',
                }}
                id="SlidePrev"
              >
                <ArrowBackIosRoundedIcon style={{ fontSize: '4vh' }} />
              </button>
            )}
            {
              <button
                onClick={goBack}
                disabled={!(isReviewMode && cardUuid) || !parentCardId}
                className={classnames({
                  'is-highlighted': allowHighlight && isReviewMode && cardUuid,
                  'back-button-animation':
                    allowHighlight && isReviewMode && cardUuid,
                })}
                style={{
                  cursor:
                    isReviewMode && !enableCarousel ? 'pointer' : 'normal',
                  opacity: isReviewMode && !enableCarousel ? '1' : '0',
                }}
              >
                <ArrowBackIosRoundedIcon style={{ fontSize: '4vh' }} />
              </button>
            }
          </div>
          <PhotoGrid />
          {/*   {!header?.videoUrl && (
            <div
              className="carousel-nav"
              style={{ justifyContent: 'flex-end' }}
            >
              {enableCarousel ? (
                <button
                  disabled={!(enableCarousel && finalCardUuid)}
                  onClick={() => Slide('next')}
                  style={{
                    cursor: !(enableCarousel && finalCardUuid)
                      ? 'normal'
                      : 'pointer',
                    opacity: !(enableCarousel && finalCardUuid) ? '0' : '1',
                  }}
                  id="SlideNext"
                >
                  <ArrowForwardIosRoundedIcon style={{ fontSize: '4vh' }} />
                </button>
              ) : null}
              <div className="spacer-y" />
            </div>
          )} */}
        </div>

        <FooterLogo />
      </div>
    </>
  )
}

export default Content
