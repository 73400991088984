import { useStateValue } from '../state-provider/StateProvider'
import { setIndexScroll } from '../slider/slider.action'

function SliderNav() {
  const [{ scrollSnaps, indexScroll }, dispatch] = useStateValue()
  function scrollTo(index) {
    dispatch(setIndexScroll({ indexScroll: index }))
  }
  return (
    <div id="navContainer">
      <div id="navDots">
        {scrollSnaps.map((_, index) => (
          <div
            className={'navDot ' + (index === indexScroll ? 'selected' : '')}
            key={index}
            onClick={() => scrollTo(index)}
          />
        ))}
      </div>
    </div>
  )
}

export default SliderNav
