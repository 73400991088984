import validator from 'validator'
export function blockBackNavigation(e) {
  window.history?.pushState(null, document.title, window.location.href)
}

export function isValidUuid(uuid) {
  if (uuid !== undefined) {
    if (validator.isUUID(uuid)) {
      return true
    }
  }
  return false
}

export function kFormatter(num) {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num)
}

export function asset(file) {
  return (
    (process.env.REACT_APP_ENV === 'staging'
      ? process.env.PUBLIC_URL
      : process.env.REACT_APP_ENV === 'testing'
      ? 'https://testing-sp.contester.net'
      : 'https://sp.contester.net') +
    '/' +
    file
  )
}

export function formatTime(seconds = 0) {
  let s = Math.floor(seconds % 60)
  let m = Math.floor((seconds / 60) % 60)

  // Always show at least one digit of minutes.
  m = `${m < 10 ? `0${m}` : m}:`

  // Check if leading zero is need for seconds
  s = s < 10 ? `0${s}` : s

  return m + s
}
