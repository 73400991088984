import classnames from 'classnames'
import { detect } from 'detect-browser'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { isMobile, useMobileOrientation } from 'react-device-detect'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'
import '../../styles/reset.css'
import { isValidUuid } from '../../utils/utils'
import { ws } from '../../events/ws'
import Content from '../content/Content'
import FooterContainer from '../footer/footer.container'
import { useStateValue } from '../state-provider/StateProvider'
import {
  enableCarouselCreator,
  enableReviewMode,
  incrementLoadCount,
  setBrand,
  setCustomNav,
  setDrawerInfo,
  setFinalCard,
  setInputForms,
  setIsLoading,
  setIsSetVideoCurrentTime,
  setItems,
  setMediaLink,
  setParentCard,
  setProducts,
  setCurrentCard,
  setSpId,
  setTitleStyle,
  setTotalInteractions,
  setWidgetEmbedded,
  setWidgetLocation,
  slide,
  setSpHash,
  setSequenceStatistics,
} from './App.action'
import './App.css'

interface Props {
  trackers: string[]
}

const App: React.FC<Props> = ({ trackers }) => {
  const [
    {
      api_url,
      cardUuid,
      isReviewMode,
      parentCardId,
      sequencePollUuid,
      sequencePollHash,
      isWidgetMaximized,
      isWidgetEmbedded,
      isWidgetCustomNav,
      header,
      finalCardUuid,
      items,
      widgetLocation,
    },
    dispatch,
  ] = useStateValue()
  const [preloadedCards, setPreloadedCards] = useState<any[]>([])
  const [prevVideoHeading, setPrevVideoHeading] = useState('')
  const [isContesterBrandingDisplayed, setLogoVisibility] = useState(false)
  const [fonts, setFonts] = useState<{ name: string; url: string }[]>([])
  const [isInitial, setIsInitial] = useState(true)
  const [apiData, setApiData] = useState<{
    cardId: string
    sessionTags: string[]
  }>()
  const lastLocation = useLastLocation()

  function Slide(to: string) {
    dispatch(slide({ to: to }))
  }

  let { uuidParam, cardUuidParam } = useParams<{
    uuidParam: string
    cardUuidParam: string
  }>()

  let history = useHistory()
  const location = useLocation()

  function goBack() {
    dispatch(setIsLoading({ isLoading: false }))
    dispatch(setFinalCard({ cardUuid: null }))
    dispatch(setIsSetVideoCurrentTime({ isSetVideoCurrentTime: false }))
    history.push(
      '/' +
        sequencePollUuid +
        '/' +
        (parentCardId ? parentCardId : '') +
        history?.location?.search,
    )
  }
  function restartSP() {
    history.push('/' + sequencePollUuid + history?.location?.search)
    dispatch(setIsLoading({ isLoading: false }))
    dispatch(setFinalCard({ cardUuid: null }))
  }

  // i don't think this block is necessary anymore, it creates this bug that once you navigate to a specific card and then click page refresh, it moves one level up
  //useEffect(() => {
  //  return () => {
  //    if (history.action === 'POP') {
  //      if (cardUuid) {
  //        handleBackNavigation();
  //      }
  //    }
  //  };
  //});

  let handleBeforeunload = () => {
    ws.close()
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload)
    return () => window.removeEventListener('beforeunload', handleBeforeunload)
  }, [])

  useEffect(() => {
    // This effect opens Safari instead of the Linkedin Browser in iOS devices.
    const browser = detect()
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    let isChromeiOs = browser?.name === 'crios'
    let isIOS = browser?.os === 'iOS'

    if (browser?.name === 'ios-webview') {
      // window.location = 'safari-https://' + window.location.hostname + window.location.pathname;
      window.location.href =
        'googlechrome://' + window.location.hostname + window.location.pathname
    }
  }, [])

  function handleBackNavigation() {
    isReviewMode === true ? goBack() : restartSP()
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (sequencePollHash === undefined) {
        dispatch(setSpHash({ hash: 'BACKEND_UNAVAILABLE', postToWidget: true }))
      }
    }, 1000)

    return () => clearTimeout(timeout)
  }, [sequencePollHash])

  useEffect(() => {
    if (isValidUuid(uuidParam)) {
      dispatch(setSpId({ uuid: uuidParam }))
      ws.init(
        uuidParam,
        dispatch,
        history,
        widgetLocation ?? window.location.href,
      )
    } else {
      if (process.env.REACT_APP_ENV === 'development') {
        // dev code
      } else {
        // production code
        window.location.replace('https://contester.net')
        return
      }
    }

    if (sequencePollHash === undefined) {
      return
    }

    if (widgetLocation === undefined) {
      return
    }

    let fetchUrl = api_url + '/sequencepolls/' + uuidParam

    //check selected card uuid
    // if (isValidUuid(cardUuidParam)) {
    //   fetchUrl += '?cardId=' + cardUuidParam;
    // }

    function appendQueryParameter(param: string) {
      if (fetchUrl.indexOf('?') > -1) {
        fetchUrl += '&' + param
      } else {
        fetchUrl += '?' + param
      }
    }

    if (isWidgetCustomNav) {
      appendQueryParameter('customRouting=true')
    }
    if (isWidgetEmbedded) {
      appendQueryParameter('widget=true')
    }
    appendQueryParameter('h=' + sequencePollHash)

    if (location.search.length > 1) {
      // it is important to append only contester_ query params. Otherwise browser and cloudflare caches won't do much for us
      location.search
        .substring(1)
        .split('&')
        .forEach(param => {
          if (param.startsWith('contester')) {
            appendQueryParameter(param)
          }
        })
    }

    function navigate(tree: any, cardId: string | undefined): any | undefined {
      let found = undefined

      if (cardId === undefined || tree.cardId === cardId) {
        found = tree
      }
      if (!found) {
        tree.items.forEach(function (item: any) {
          let result = navigate(item, cardId)
          if (result) {
            found = result
          }
        })
      } else {
        let preload: any[] = []
        found.items.forEach(function (child: any) {
          preload.push(child)
          child.items.forEach(function (grandChild: any) {
            preload.push(grandChild)
          })
        })
        setPreloadedCards(preload)
      }

      return found
    }

    fetch(fetchUrl)
      .then(res => res.json())
      .then(res => navigate(res, cardUuidParam))
      .then(async result => {
        dispatch(setIsLoading(true))
        const styles = JSON.parse(
          result?.cardStyleParameters?.headingTitleStyle || '{}',
        )
        dispatch(setTitleStyle({ titleStyle: styles }))
        dispatch(
          enableCarouselCreator({
            enableCarousel: result.displayOptions?.isCarouselEnabled,
          }),
        )
        dispatch(
          enableReviewMode({
            isReviewMode: result.displayOptions?.isReviewModeEnabled === true,
          }),
        )
        dispatch(setParentCard({ parentCardId: result.parentCardId }))
        dispatch(setSequenceStatistics({ statistics: result.statistics }))
        try {
          if (result.header?.videoUrl) {
            setPrevVideoHeading(new URL(result.header?.videoUrl)?.pathname)
          }
        } catch (err) {}

        setApiData(result)

        dispatch(setCurrentCard({ cardUuid: result.cardId }))
        if (result.displayOptions?.isReviewModeEnabled === false) {
          // is sequence poll mode:
          window.history?.pushState(null, document.title, window.location.href)
          window.addEventListener('popstate', handleBackNavigation)
          if (!lastLocation) {
            history.push('/' + uuidParam + history?.location?.search)
            return
          }
        }
        // test comment
        setFonts(result?.fonts?.fonts)
        setIsInitial(false)

        dispatch(setInputForms({ inputForms: result.inputforms }))

        // here we instantianate new image with onload callbacks to know when all images are cached.
        if (result.items?.length === 0) {
          if (!lastLocation && isValidUuid(uuidParam)) {
            history.push(
              '/' +
                uuidParam +
                '/' +
                result.parentCardId +
                history?.location?.search,
            )
            return
          }
          dispatch(setFinalCard({ cardUuid: cardUuid }))
          dispatch(setProducts({ products: result.products }))
        }
        let new_items = result.items?.map((i: any) => {
          let img = new Image()
          img.onload = () => {
            dispatch(incrementLoadCount())
          }
          img.src = i.mediaLink
          return {
            ...i,
            image: img,
            title: result.title,
            header: result.header,
          }
        })
        if (result?.displayOptions?.isContesterBrandingDisplayed) {
          setLogoVisibility(true)
        }
        if (result.items?.length > 0) {
          dispatch({
            type: 'SET_HEADER',
            header:
              result.header.title === '' &&
              result.header.videoUrl === null &&
              result.header.imageUrl === null
                ? null
                : result.header,
          })
          dispatch(setIsLoading(false))
          dispatch(setItems({ items: new_items }))
        }

        dispatch({
          type: 'SET_OVERLAY_CARDS',
          overlayCards: result.overlayCards,
        })
        dispatch(setDrawerInfo({ drawerInfo: result.drawer }))
        dispatch(setBrand({ brand: result.brand }))
        dispatch(
          setTotalInteractions({
            totalInteractions: result.statistics?.totalInteractions ?? 0,
          }),
        )
        dispatch(setMediaLink({ mediaLink: result.mediaLink }))

        let targetWindow = window.parent
        targetWindow?.postMessage({ type: 'loaded' }, '*')
      })
      .catch(e => {
        console.log(e)
      })

    return () => {
      window.removeEventListener('popstate', handleBackNavigation)
    }
  }, [
    history,
    uuidParam,
    cardUuidParam,
    cardUuid,
    sequencePollHash,
    dispatch,
    api_url,
    isWidgetCustomNav,
    widgetLocation,
  ]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleWidgetMessage = (event: MessageEvent) => {
    if (event?.data?.type === 'widget-embedded') {
      dispatch(setWidgetEmbedded({ status: true }))
    }
    if (event?.data?.type === 'widget-location') {
      dispatch(setWidgetLocation({ location: event?.data?.value }))
    }
    if (event?.data?.type === 'widget-custom-nav') {
      dispatch(setCustomNav({ status: true }))
    }
    if (event?.data?.type === 'widget-experience-hash') {
      dispatch(setSpHash({ hash: event?.data?.value, postToWidget: false }))
    }
  }

  useEffect(() => {
    if (apiData) {
      ReactGA.pageview(apiData?.sessionTags?.join(',') || location.pathname, [
        ...trackers,
      ])
    }
  }, [apiData?.cardId])

  useEffect(() => {
    if (window.parent) window.parent.postMessage('widget-status', '*')
    window.addEventListener('message', handleWidgetMessage, true)
    return () => {
      window.removeEventListener('message', handleWidgetMessage, true)
    }
  }, [])

  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  useEffect(() => {
    window.addEventListener('resize', appHeight)
    return () => {
      window.removeEventListener('resize', appHeight)
    }
  }, [])

  useEffect(() => {
    if (isInitial && fonts) {
      fonts.map(font => {
        const style = document.createElement('style')
        style.textContent = `@import url("${font.url}")`

        document.head.appendChild(style)
      })
    }
  }, [fonts, isInitial])

  const { isLandscape } = useMobileOrientation()
  const landscapeMode = isLandscape && isMobile

  return (
    <>
      <Helmet>
        <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />
      </Helmet>
      <div className="App">
        <div id="main">
          <div
            id="sequence-poll"
            className={classnames({
              'is-embedded': isWidgetEmbedded,
              'is-maximized': isWidgetMaximized,
              'has-footer-logo': isContesterBrandingDisplayed,
              'has-video': header?.videoUrl || header?.liveStreamUrl,
              'is-landscape': landscapeMode,
              'has-header':
                !(finalCardUuid || header === null) &&
                !header?.imageUrl &&
                !header?.videoUrl &&
                !header?.liveStreamUrl,
            })}
          >
            <Content
              prevVideoHeading={prevVideoHeading}
              setPrevVideoHeading={setPrevVideoHeading}
            />
            {!isEmpty(items) && <FooterContainer />}
          </div>
        </div>
        <div id="brandContent"></div>
      </div>
      <div style={{ display: 'none' }}>
        {preloadedCards.map(card => (
          <img key={card.cardId} src={card.mediaLink} />
        ))}
      </div>
    </>
  )
}

export default App
