import React, { useRef, useEffect, useState } from 'react'
import { useStateValue } from '../state-provider/StateProvider'
import './OverlayButtons.css'
import ProductTag from '../product-tag/ProductTag'
import Slider from '../slider/Slider'
import { setCartDrawer, setShowDescription } from './overlay-buttons.action'
import { setVariantDrawer } from '../variant-drawer/variant-drawer.action'

export default function OverlayButtons({
  items,
  showArtifacts,
  setShowArtifacts,
}) {
  const [
    {
      products,
      finalCardUuid,
      overlayCards,
      showDescription,
      enableCarousel,
      drawerInfo,
      inputForms,
      sequencePollUuid,
      api_url,
      showCartDrawer,
      showVariantDrawer,
    },
    dispatch,
  ] = useStateValue()

  const descRef = useRef(null)
  const overlayRef = useRef(null)
  const drawerRef = useRef(null)
  const productTagRef = useRef(null)
  const cardRef = useRef(null)
  const cardList = useRef(null)
  const bottomOverlay = useRef(null)

  useEffect(() => {
    dispatch(setShowDescription({ showDescription: false }))
  }, [dispatch])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        descRef.current &&
        !descRef.current?.contains(event.target) &&
        showDescription
      ) {
        dispatch(setShowDescription({ showDescription: false }))
      }
      if (
        overlayRef.current &&
        overlayRef.current?.contains(event.target) &&
        !drawerRef.current?.contains(event.target) &&
        !productTagRef.current?.contains(event.target) &&
        !cardRef.current?.contains(event.target) &&
        !descRef.current?.contains(event.target)
      ) {
        setShowArtifacts(!showArtifacts)
      }
      if (showArtifacts && cardList.current?.contains(event.target)) {
        setShowArtifacts(true)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [descRef, overlayRef, showArtifacts, setShowArtifacts, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setShowArtifacts(!showDescription)
  }, [showDescription, setShowArtifacts])
  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
  const [isSent, setIsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [email, setEmail] = useState('')

  async function sendEmail(e) {
    e.preventDefault()
    setIsLoading(true)
    fetch(
      api_url + '/sequencepolls/' + sequencePollUuid + '/sessionParameter',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: 'END_USER_INPUT_EMAIL',
          value: email,
        }),
      },
    )
      .then(res => res.json())
      .then(async result => {
        setIsSent(true)
        setIsLoading(false)
        await sleep(1500)
        window.location.reload()
      })
  }
  function openVariantDrawer() {
    dispatch(setVariantDrawer({ showVariantDrawer: true }))
  }

  return (
    <>
      <div
        className={finalCardUuid ? 'show' : 'hide'}
        ref={overlayRef}
        id="overlay-buttons"
      >
        {enableCarousel ? (
          <Slider
            items={items}
            showArtifacts={showArtifacts}
            setShowArtifacts={setShowArtifacts}
          />
        ) : null}
        {products?.map((p, index) => (
          <ProductTag
            p={p}
            showArtifacts={showArtifacts}
            productTagRef={productTagRef}
            key={index}
          />
        ))}

        <div className="bottom-overlay" ref={bottomOverlay}>
          <div id="card-list" ref={cardList}>
            {inputForms?.map((i, index) => (
              <div
                key={index}
                ref={cardRef}
                className={
                  'msg-card input-form ' + (!showArtifacts ? 'hide' : 'show')
                }
              >
                <div className="title">{i.title}</div>
                <form onSubmit={sendEmail} className="input-container">
                  <p className={isSent ? 'show' : 'hide'}>{i.thankyouLabel}</p>

                  <input
                    className={!isSent ? 'show' : 'hide'}
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder={i.inputs[0].label}
                    id="email"
                    name="email"
                    disabled={isLoading}
                    required
                  ></input>
                  <button
                    disabled={isLoading}
                    className={!isSent ? 'show' : 'hide'}
                    type="submit"
                  >
                    {isLoading ? 'Sending...' : i.buttonLabel}
                  </button>
                </form>
              </div>
            ))}

            {overlayCards.map((c, index) => (
              <div
                key={index}
                ref={cardRef}
                className={'msg-card ' + (!showArtifacts ? 'hide' : 'show')}
                onClick={() => (window.location.href = c.link)}
              >
                <p className="heading">{c.heading1}</p>
                <p className="sub-heading">{c.heading2}</p>
              </div>
            ))}
            {products[0]?.variants.length > 0 && (
              <div
                ref={cardRef}
                style={{
                  display:
                    window.location.hostname === 'staging-s.contester.net' ||
                    window.location.hostname === 'localhost'
                      ? 'block'
                      : 'none',
                }}
                className={
                  'msg-card addtocart ' + (!showArtifacts ? 'hide' : 'show')
                }
                onClick={openVariantDrawer}
              >
                <p className="heading">ADD TO CART</p>
              </div>
            )}
          </div>
          {drawerInfo && (
            <div
              id="toggleDescription"
              style={{ position: enableCarousel ? 'sticky' : 'relative' }}
              ref={drawerRef}
              className={!showArtifacts ? 'hide' : 'show'}
              onClick={() =>
                dispatch(
                  setShowDescription({ showDescription: !showDescription }),
                )
              }
            >
              <p className="sub-heading">Description</p>
            </div>
          )}
        </div>

        <div
          style={{ display: drawerInfo ? 'block' : 'none' }}
          id="description"
          ref={descRef}
          className={showDescription ? 'show' : 'hide'}
        >
          <div className="sticky-heading">
            <img alt="card heading" src={drawerInfo?.imageLink} />
          </div>
          <h1>{drawerInfo?.title}</h1>

          <p>{drawerInfo?.description}</p>
          <div className="drawer-link">
            <a
              rel="noreferrer"
              target="_blank"
              className="cta"
              href={drawerInfo?.link || '/#'}
            >
              {drawerInfo?.linkTitle}
            </a>
          </div>
        </div>
        <div
          id="gray-overlay"
          onClick={() => dispatch(setCartDrawer({ showCartDrawer: false }))}
          className={
            showDescription || showCartDrawer || showVariantDrawer
              ? 'show'
              : 'hide'
          }
        ></div>
      </div>
    </>
  )
}
