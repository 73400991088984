import ReactGA from 'react-ga'
import { gaTrackerNames } from '../index'

export const GA_PlayVideo = () => {
  ReactGA.event(
    {
      category: 'Video',
      action: 'Play',
      label: 'Play Video',
    },
    [...gaTrackerNames],
  )
}

export const GA_PauseVideo = () => {
  ReactGA.event(
    {
      category: 'Video',
      action: 'Pause',
      label: 'Pause Video',
    },
    [...gaTrackerNames],
  )
}

export const GA_MuteVideo = () => {
  ReactGA.event(
    {
      category: 'Video',
      action: 'Mute',
      label: 'Mute Video',
    },
    [...gaTrackerNames],
  )
}

export const GA_UnmuteVideo = () => {
  ReactGA.event(
    {
      category: 'Video',
      action: 'Unmute',
      label: 'Unmute Video',
    },
    [...gaTrackerNames],
  )
}

export const GA_ProductClick = (sessionTags: string, brand: string) => {
  ReactGA.event(
    {
      category: `Product ${brand}`,
      action: 'Click',
      label: sessionTags,
    },
    [...gaTrackerNames],
  )
}

export const GA_BackButton = () => {
  ReactGA.event(
    {
      category: 'Button',
      action: 'Click',
      label: 'Back Button',
    },
    [...gaTrackerNames],
  )
}
