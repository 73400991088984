import React, { useState, useRef } from 'react'
import { isEmpty } from 'lodash'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ShareIcon from '@material-ui/icons/Share'
import BugReportIcon from '@material-ui/icons/BugReport'
import LinkIcon from '@material-ui/icons/Link'
import './TopBar.css'
import { useStateValue } from '../state-provider/StateProvider'
import DebugModal from '../debug-modal/DebugModal'
import classnames from 'classnames'
function TopBar() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [{ brand, header, items }] = useStateValue()
  const open = Boolean(anchorEl)
  const [copyLinkText, setCopyLinkText] = useState('Copy link')

  const menuRef = useRef(null)

  const [shareData] = useState({
    title: 'Contester',
    text: 'Sequence Poll',
    url: window.location.href,
  })
  const shareClick = async () => {
    try {
      await navigator.share(shareData)
    } catch (err) {
      console.log('Error: ' + err)
    }
  }
  const handleClick = event => {
    setCopyLinkText('Copy link')
    setAnchorEl(event.currentTarget)
  }

  function copyLink() {
    navigator.clipboard.writeText(window.location.href)
    setCopyLinkText('Copied!')
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const [showDebug, setShowDebug] = useState(false)

  return (
    <>
      {!isEmpty(items) && (
        <div
          className={classnames('TopBar', {
            'no-header': header === null,
          })}
        >
          <div className="company-info">
            <div className="logo-wrap">
              <img
                draggable="false"
                alt=""
                src={brand?.imageLink}
                className="logo"
              />
            </div>
            <div className="company-name">{brand?.title}</div>
          </div>
          <div className="right-menu">
            <IconButton
              id="options-mobile"
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              ref={menuRef}
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: 48 * 4.5,
                  width: '20ch',
                  boxShadow: '0px 3px 14px rgba(0,0,0,0.07)',
                  borderRadius: '1.5vh',
                },
              }}
            >
              <MenuItem onClick={copyLink}>
                <LinkIcon />
                <div style={{ marginLeft: '10%' }}>{copyLinkText}</div>
              </MenuItem>
              <MenuItem onClick={shareClick}>
                <ShareIcon />
                <div style={{ marginLeft: '10%' }}>Share via</div>
              </MenuItem>
              {(window.location.hostname === 'staging-s.contester.net' ||
                window.location.hostname === 'localhost') && (
                <MenuItem onClick={() => setShowDebug(true)}>
                  <BugReportIcon />
                  <div style={{ marginLeft: '10%' }}>Debug info</div>
                </MenuItem>
              )}
            </Menu>

            <DebugModal showDebug={showDebug} setShowDebug={setShowDebug} />
          </div>
        </div>
      )}
    </>
  )
}

export default TopBar
