import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { detect } from 'detect-browser'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    wordBreak: 'break-all',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export default function DebugModal({ showDebug, setShowDebug }) {
  const classes = useStyles()

  const handleClose = () => {
    setShowDebug(false)
  }

  const browser = useState(detect())

  function safari() {
    window.location =
      'safari-https://' + window.location.hostname + window.location.pathname
  }
  function chrome() {
    window.location =
      'googlechrome://' + window.location.hostname + window.location.pathname
  }
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showDebug}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showDebug}>
        <div className={classes.paper}>
          <h2 id="transition-modal-title">Debug Info</h2>
          <p id="transition-modal-description">
            {'isSafari: ' +
              /^((?!chrome|android).)*safari/i.test(navigator.userAgent)}
            <br></br>
            {'Browser: ' + JSON.stringify(browser)}

            <br></br>
            {'!isChromeiOs && !isSafari && isIOS: ' +
              (browser.name !== 'crios' &&
                /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
                browser.os === 'iOS')}

            <br></br>

            <button onClick={safari}>Open Safari</button>
            <button onClick={chrome}>Open Chrome</button>
          </p>
        </div>
      </Fade>
    </Modal>
  )
}
