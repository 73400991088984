import React from 'react'
import ReactDOM from 'react-dom'
import App from './modules/app/App'
import './styles/index.css'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import reportWebVitals from './modules/report-web-vitals/reportWebVitals'
import { StateProvider } from './modules/state-provider/StateProvider'
import reducer, { initialState } from './store/reducer'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import ReactGA from 'react-ga'

let gaTrackers = []
export let gaTrackerNames = ['contesterTracker', 'clientTracker']

if (process.env.REACT_APP_ENV === 'production') {
  gaTrackers = [
    {
      trackingId: 'UA-192831380-3',
      gaOptions: {
        name: 'contesterTracker',
      },
    },
  ]
} else if (process.env.REACT_APP_ENV === 'staging') {
  gaTrackers = [
    {
      trackingId: 'UA-192831380-2',
      gaOptions: {
        name: 'contesterTracker',
      },
    },
    {
      trackingId: 'UA-192831380-1',
      gaOptions: {
        name: 'clientTracker',
      },
    },
    { debug: true },
  ]
}

ReactGA.initialize(gaTrackers, {
  debug: process.env.REACT_APP_ENV === 'localhost' ? true : false,
})

Sentry.init({
  dsn: 'https://f2f3f9b5d3504fd9b84a4a94bd367740@o590637.ingest.sentry.io/5994972',
  integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,
  replaysSessionSampleRate: 0.05,
})

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <Router>
        <LastLocationProvider>
          <Switch>
            <Route exact path="/">
              <App trackers={gaTrackerNames} />
            </Route>
            <Route exact path="/:uuidParam">
              <App trackers={gaTrackerNames} />
            </Route>
            <Route path="/:uuidParam/:cardUuidParam">
              <App trackers={gaTrackerNames} />
            </Route>
          </Switch>
        </LastLocationProvider>
      </Router>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('contester-end-user-root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
