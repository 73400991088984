import {
  SET_IS_LOADING,
  SET_FINAL_CARD_UUID,
  SLIDE_TO,
  SET_SP_ID,
  SET_INPUT_FORMS,
  SET_PRODUCTS,
  SET_ITEMS,
  SET_DRAWER_INFO,
  SET_BRAND,
  SET_TOTAL_INTERACTIONS,
  SET_MEDIALINK,
  SET_CARD_UUID,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SET_VARIANT_DRAWER,
  SET_CART_DRAWER,
  SET_HEADER,
  SET_PARENT_CARD,
  SET_INDEXSCROLL,
  SET_OVERLAY_CARDS,
  ENABLE_CAROUSEL,
  ENABLE_REVIEW_MODE,
  SET_SHOW_DESCRIPTION,
  SET_SCROLLSNAPS,
  INCREMENT_LOAD_COUNT,
  CLEAR_LOAD_COUNT,
  SET_TITLE,
  SET_VIDEO_CURRENT_TIME,
  SET_ISSET_VIDEO_CURRENT_TIME,
  IS_WIDGET_MAXIMIZED,
  IS_WIDGET_EMBEDDED,
  IS_WIDGET_CUSTOM_NAV,
  TITLE_STYLE,
  SET_SP_HASH,
  SET_SEQUENCE_STATISTICS,
  SET_SEQUENCE_LIVE_DATA,
  SET_WIDGET_LOCATION,
} from '../constants/actions'

import { EventInteractionType, ws } from '../events/ws'

export const initialState = {
  sequencePollUuid: '', //default
  sequencePollHash: undefined,
  cardUuid: null,
  title: null,
  mediaLink: null,
  items: [],
  api_url:
    (process.env.REACT_APP_ENV === 'staging'
      ? 'https://staging-api.contester.net'
      : 'https://api.contester.net') + '/api/public',
  finalCardUuid: null,
  products: [],
  brand: null,
  drawerInfo: null,
  totalInteractions: null,
  isLoading: false,
  loadCount: 1,
  showDescription: false,
  isReviewMode: true,
  enableCarousel: false,
  sliderTo: null,
  indexScroll: null,
  scrollSnaps: [],
  overlayCards: [],
  liveData: {},
  statistics: {},
  parentCardId: null,
  header: null,
  titleStyle: null,
  inputForms: [],
  showCartDrawer: false,
  showVariantDrawer: false,
  cart: [],
  prevVideoCurrentTime: 0,
  isSetVideoCurrentTime: false,
  isWidgetMaximized: false,
  isWidgetEmbedded: false,
  isWidgetCustomNav: false,
  widgetLocation: null,
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        cart: [...state.cart, action.new_product],
      }
    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter((p: any) => p.variantId != action.variantId),
      }
    case SET_VARIANT_DRAWER:
      return {
        ...state,
        showVariantDrawer: action.showVariantDrawer,
      }
    case SET_CART_DRAWER:
      return {
        ...state,
        showCartDrawer: action.showCartDrawer,
      }

    case SET_INPUT_FORMS:
      return {
        ...state,
        inputForms: action.inputForms,
      }

    case SET_HEADER:
      return {
        ...state,
        header: action.header,
      }
    case SET_PARENT_CARD:
      return {
        ...state,
        parentCardId: action.parentCardId,
      }
    case SET_OVERLAY_CARDS:
      return {
        ...state,
        overlayCards: action.overlayCards,
      }
    case ENABLE_CAROUSEL:
      return {
        ...state,
        enableCarousel: action.enableCarousel,
      }
    case ENABLE_REVIEW_MODE:
      return {
        ...state,
        isReviewMode: action.isReviewMode,
      }
    case SET_INDEXSCROLL:
      return {
        ...state,
        indexScroll: action.indexScroll,
      }
    case SET_SCROLLSNAPS:
      return {
        ...state,
        scrollSnaps: action.scrollSnaps,
      }
    case SLIDE_TO:
      return {
        ...state,
        slideTo: action.to,
      }
    case SET_SP_ID: {
      return {
        ...state,
        sequencePollUuid: action.uuid,
      }
    }
    case SET_SP_HASH: {
      if (
        action.hash !== undefined &&
        action.hash !== '' &&
        state.isWidgetEmbedded &&
        action.postToWidget
      ) {
        if (window.parent)
          window.parent.postMessage(
            { type: 'experience-hash', value: action.hash },
            '*',
          )
      }
      return {
        ...state,
        sequencePollHash: action.hash,
      }
    }
    case SET_SHOW_DESCRIPTION:
      return {
        ...state,
        showDescription: action.showDescription,
      }
    case INCREMENT_LOAD_COUNT:
      return {
        ...state,
        loadCount: state.loadCount + 1,
      }
    case CLEAR_LOAD_COUNT:
      return {
        ...state,
        loadCount: 0,
      }
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case SET_DRAWER_INFO:
      return {
        ...state,
        drawerInfo: action.drawerInfo,
      }
    case SET_SEQUENCE_LIVE_DATA:
      return {
        ...state,
        liveData: action.liveData,
        sessionData: action.sessionData,
      }
    case SET_SEQUENCE_STATISTICS:
      return {
        ...state,
        statistics: action.statistics,
      }
    case SET_BRAND:
      return {
        ...state,
        brand: action.brand,
      }
    case SET_CARD_UUID:
      ws.setCard(action.cardUuid)
      return {
        ...state,
        cardUuid: action.cardUuid,
      }
    case SET_TOTAL_INTERACTIONS:
      return {
        ...state,
        totalInteractions: action.totalInteractions,
      }
    case SET_FINAL_CARD_UUID:
      return {
        ...state,
        finalCardUuid: action.cardUuid,
      }
    case SET_ITEMS:
      return {
        ...state,
        items: action.items,
      }
    case SET_TITLE:
      return {
        ...state,
        title: action.title,
      }
    case SET_MEDIALINK:
      return {
        ...state,
        mediaLink: action.mediaLink,
      }
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.products,
      }
    case SET_VIDEO_CURRENT_TIME:
      return {
        ...state,
        prevVideoCurrentTime: action.prevVideoCurrentTime,
      }
    case SET_ISSET_VIDEO_CURRENT_TIME:
      return {
        ...state,
        isSetVideoCurrentTime: action.isSetVideoCurrentTime,
      }
    case IS_WIDGET_MAXIMIZED: {
      return {
        ...state,
        isWidgetMaximized: action.isWidgetMaximized,
      }
    }
    case SET_WIDGET_LOCATION: {
      return {
        ...state,
        widgetLocation: action.location,
      }
    }
    case IS_WIDGET_EMBEDDED:
      return {
        ...state,
        isWidgetEmbedded: true,
      }
    case IS_WIDGET_CUSTOM_NAV:
      return {
        ...state,
        isWidgetCustomNav: true,
      }
    case TITLE_STYLE:
      return {
        ...state,
        titleStyle: action.titleStyle,
      }
    default:
      return state
  }
}

export default reducer
