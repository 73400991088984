import { CLEAR_LOAD_COUNT, SET_TITLE } from '../../constants/actions'

const clearLoadCount = payload => ({
  type: CLEAR_LOAD_COUNT,
  ...payload,
})

const setTitle = payload => ({
  type: SET_TITLE,
  ...payload,
})

export { clearLoadCount, setTitle }
