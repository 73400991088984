import { useEffect, useRef, useState } from 'react'
import { useStateValue } from '../state-provider/StateProvider'
import './CartMobile.css'
import CartMobileRow from '../cart-mobile-row/CartMobileRow'
import { CartProduct } from '../../types/index'
const CartMobile = () => {
  const [{ showCartDrawer, cart, api_url, cardUuid, sequencePollUuid }] =
    useStateValue()

  function checkOut() {
    fetch(
      api_url + '/sequencepolls/' + sequencePollUuid + '/sessionParameter',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: 'GO_TO_CART',
          value: cardUuid,
        }),
      },
    )
      .then(res => res.json())
      .then(async result => {
        window.location.href = result.redirectUrl
      })
  }
  const cartDrawerRef = useRef(null)
  const [totalPrice, setTotalPrice] = useState<number>(0)

  useEffect(() => {
    let count = 0
    cart.forEach((p: CartProduct) => {
      count += p.price.amount
    })
    setTotalPrice(count)
  }, [cart])

  return (
    <div
      ref={cartDrawerRef}
      className={'cart-mobile-drawer ' + (showCartDrawer ? 'show' : 'hide')}
    >
      {cart.length === 0 && (
        <h3 className="cart-is-empty">Your cart is empty</h3>
      )}
      <div className="cart-list">
        {cart.map((p: CartProduct) => (
          <CartMobileRow key={p.variantId} product={p} />
        ))}
      </div>
      <div
        className="checkout"
        style={{ display: cart.length === 0 ? 'none' : 'flex' }}
      >
        <div className="total">
          Total: <br></br>
          <div className="total-price">
            {totalPrice.toFixed(2)} {cart[0]?.price.currency}{' '}
          </div>
        </div>
        <button disabled={cart.length === 0} onClick={checkOut}>
          CHECK OUT
        </button>
      </div>
    </div>
  )
}

export default CartMobile
