import { ADD_TO_CART, SET_VARIANT_DRAWER } from '../../constants/actions'

const addToCard = payload => ({
  type: ADD_TO_CART,
  ...payload,
})

const setVariantDrawer = payload => ({
  type: SET_VARIANT_DRAWER,
  ...payload,
})

export { addToCard, setVariantDrawer }
