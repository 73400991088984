import {
  setCurrentCard,
  setSequenceLiveData,
  setSequenceStatistics,
  setSpHash,
  setSpId,
} from '../modules/app/App.action'

import { localStorageAvailable } from '../utils/localStorage'

enum MessageType {
  ERROR = 'ERROR',
  DEBUG = 'DEBUG',
  EVENT_INTERACTION = 'EVENT_INTERACTION',
  STATE_METRICS = 'STATE_METRICS',
}

export enum EventInteractionType {
  WIDGET_MINIMIZED = 'WIDGET_MINIMIZED',
  WIDGET_MAXIMIZED = 'WIDGET_MAXIMIZED',

  // Send cardId for the next events, i.e. sendEvent(EventInteractionType.HEADER_VIDEO_UNMUTED, cardId);
  HEADER_VIDEO_UNMUTED = 'HEADER_VIDEO_UNMUTED',
  HEADER_VIDEO_MUTED = 'HEADER_VIDEO_MUTED',
  HEADER_VIDEO_PAUSED = 'HEADER_VIDEO_PAUSED',
  HEADER_VIDEO_UNPAUSED = 'HEADER_VIDEO_UNPAUSED',
  HEADER_VIDEO_MAXIMIZED = 'HEADER_VIDEO_MAXIMIZED',
  HEADER_VIDEO_MINIMIZED = 'HEADER_VIDEO_MINIMIZED',

  CARD_DISPLAYED = 'CARD_DISPLAYED',

  CARD_DIRECT_LINK_CLICKED = 'CARD_DIRECT_LINK_CLICKED',

  SOCIAL_LIKE = 'SOCIAL_LIKE',
  SOCIAL_SHARE = 'SOCIAL_SHARE',
}

interface Message {
  experienceId: string
  type: MessageType
  payload: any
}

interface ServerMessage {
  type: string
  payload: any
}

interface ServerMessageSettings {
  logFrequencySeconds: number
  flushFrequencySeconds: number
  hash: string
  liveData: ServerSettingsLiveData
  sessionData: ServerSettingsSessionLiveData
}

interface ServerSettingsSessionLiveData {
  id: string
  creditedId: string
  queryString: string | null
}

interface ServerSettingsLiveData {
  cards: ServerSettingsLiveDataCard[]
}

interface ServerSettingsLiveDataCard {
  id: string
  liked: boolean
  shared: boolean
  totalLikes: number
  totalShares: number
  totalVideoWatched: number
}

interface ServerMessageRefresh {
  experienceId: string
  cardId: string | null
  hash: string
}

export enum MetricsType {
  videoMuted = 'videoMuted',
  videoUnmuted = 'videoUnmuted',
  videoPaused = 'videoPaused',
  videoPlaying = 'videoPlaying',
}

interface MetricsObject {
  cardId: string
  map: Map<MetricsType, number>
}

/**
 * localStorage global state
 */
interface ContesterState {
  credited: ContesterStateCredited[] | null
}

interface ContesterStateCredited {
  id: string
  host: string
  timestamp: number
}

class WS {
  private dispatch: any
  private history: any
  private location: string | null = null

  private socket: WebSocket | null = null
  private currentExperienceId: string | null = null

  private cardUuid: string | null = null

  private flushSeconds: number | null = null
  private flushIntervalHandler: NodeJS.Timeout | null = null

  private logSeconds: number | null = null
  private logIntervalHandler: NodeJS.Timeout | null = null

  private metricsMap: Map<MetricsType, () => boolean> = new Map<
    MetricsType,
    () => boolean
  >()
  private metricsSeconds: Array<MetricsObject> = new Array<MetricsObject>()

  private isStaging = process.env.REACT_APP_ENV === 'staging'

  private wsPath = this.isStaging
    ? 'wss://staging-api.contester.net:8443/api/public/ws'
    : 'wss://api.contester.net:8443/api/public/ws'

  private buffer: Message[] = []

  private log(message: string): void {
    if (this.isStaging) {
      console.log(message)
    }
  }

  private flushMetrics(): void {
    const payloads = this.metricsSeconds.map(metrics => {
      // it may seem counter-intuitive to avoid using a type-safe interface for message
      // but actually by doing this we're not including properties which do not have metrics seconds associated with them.
      // that way we save bandwidth for users

      const payload: any = {}
      metrics.map.forEach((value, key) => {
        payload[key] = value
      })
      payload['cardId'] = metrics.cardId
      return payload
    })
    if (payloads.length > 0) {
      this.send(MessageType.STATE_METRICS, payloads)
    }
    this.metricsSeconds = []
  }

  private logMetrics(): void {
    const metricsSeconds: Map<MetricsType, number> = new Map<
      MetricsType,
      number
    >()
    const logSeconds = this.logSeconds!!
    this.metricsMap.forEach((value, key) => {
      const trueState = value()
      // this.log("log metrics " + key + " = " + trueState)

      if (trueState) {
        metricsSeconds.set(key, logSeconds + (metricsSeconds.get(key) ?? 0))
      }
    })
    if (metricsSeconds.size > 0) {
      this.metricsSeconds.push({
        cardId: this.cardUuid!!,
        map: metricsSeconds,
      })
    }
  }

  registerMetrics(type: MetricsType, provider: () => boolean): void {
    this.metricsMap.set(type, provider)
  }

  close() {
    this.log('closing and flushing current messages')
    this.removeHandlers()
    this.flushMetrics()
  }

  private removeHandlers() {
    if (this.flushIntervalHandler != null) {
      this.log('cancelling existing flush interval handler')
      clearInterval(this.flushIntervalHandler)
      this.flushIntervalHandler = null
    }
    if (this.logIntervalHandler != null) {
      this.log('cancelling existing log interval handler')
      clearInterval(this.logIntervalHandler)
      this.logIntervalHandler = null
    }
  }

  private onServerMessage(message: ServerMessage): void {
    this.log('onServerMessage for type ' + message.type)

    // server sends settings on how often to log metrics and how often to flush it
    if (message.type === 'SETTINGS') {
      const settings: ServerMessageSettings = message.payload
      this.flushSeconds = settings.flushFrequencySeconds ?? 2
      this.logSeconds = settings.logFrequencySeconds ?? 1

      this.assignCreditedSessionId(settings.sessionData.creditedId)
      this.dispatch(setSpHash({ hash: settings.hash, postToWidget: true }))

      this.dispatch(
        setSequenceLiveData({
          liveData: settings.liveData,
          sessionData: settings.sessionData,
        }),
      )

      this.removeHandlers()

      this.log('flush seconds ' + this.flushSeconds)
      this.log('log seconds ' + this.logSeconds)
      this.flushIntervalHandler = setInterval(() => {
        this.flushMetrics()
      }, this.flushSeconds * 1000)
      this.logIntervalHandler = setInterval(() => {
        this.logMetrics()
      }, this.logSeconds * 1000)
    } else if (message.type === 'REFRESH') {
      const payload: ServerMessageRefresh = message.payload
      let update =
        this.currentExperienceId !== payload.experienceId ||
        this.cardUuid !== payload.cardId
      this.cardUuid = payload.cardId
      this.currentExperienceId = payload.experienceId
      this.dispatch(setSpHash({ hash: payload.hash }))
      // this.dispatch(setCurrentCard({ cardUuid: payload.cardId }))
      // this.dispatch(setSpId({ uuid: payload.experienceId }))
      if (update) {
        this.history.push('/' + payload.experienceId + '/' + payload.cardId)
      }
    }
  }

  private onWebSocketMessage(event: MessageEvent): void {
    this.log(`WS data received from server: ${event.data}`)
    try {
      this.onServerMessage(JSON.parse(event.data))
    } catch (e) {
      this.log('Error handling server message: ' + e)
    }
  }

  private initSocket(s: WebSocket): void {
    let buffer = this.buffer
    let log = (message: string) => {
      this.log(message)
    }

    s.onopen = function (e) {
      log(`WS ready`)
      while (buffer.length > 0) {
        let message = buffer.pop()!
        log(`WS clearing out buffered message '${message.type}'`)
        s.send(JSON.stringify(message))
      }
    }

    s.onmessage = (event: MessageEvent) => {
      this.onWebSocketMessage(event)
    }

    s.onclose = function (event) {
      if (event.wasClean) {
        log(
          `WS connection closed cleanly, code=${event.code} reason=${event.reason}`,
        )
      } else {
        log('WS connection died')
      }
    }

    s.onerror = function (error) {
      log(`[error] ${error}`)
    }
  }

  private send(messageType: MessageType, payload: any): void {
    if (this.socket != null && this.currentExperienceId != null) {
      let message: Message = {
        experienceId: this.currentExperienceId,
        type: messageType,
        payload: payload,
      }

      if (this.socket.readyState == 1) {
        // 1: ready
        // send immediately
        this.log(`WS sending message '${message.type}'`)
        this.socket.send(JSON.stringify(message))
      } else {
        this.log(`WS not ready, adding message '${message.type}' to buffer`)
        // put to buffer to send out during onopen event
        this.buffer.push(message)
      }
    }
  }

  sendEvent(
    type: EventInteractionType,
    cardId: string | null = this.cardUuid,
  ): void {
    this.log(`WS: ${type}: ${this.cardUuid}`)
    this.send(MessageType.EVENT_INTERACTION, {
      type: type,
      extra: {
        cardId: cardId,
      },
    })
  }

  setCard(cardUuid: string): void {
    if (this.cardUuid != cardUuid) {
      this.log('set card ' + cardUuid)
      this.cardUuid = cardUuid
      this.sendEvent(EventInteractionType.CARD_DISPLAYED)
    }
  }

  daysBetween(date1: Date, date2: Date) {
    const ONE_DAY = 1000 * 60 * 60 * 24
    const differenceMs = Math.abs(date1.getTime() - date2.getTime())
    return Math.floor(differenceMs / ONE_DAY)
  }

  private getGlobalState(): ContesterState {
    let defaultState = { credited: [] }
    let stateString = localStorage.getItem('contester_state')
    if (stateString == null) {
      return defaultState
    } else {
      let state
      try {
        state = JSON.parse(stateString) as ContesterState
      } catch (e) {
        state = defaultState
      }
      return state
    }
  }

  /**
   * WS Settings message contains session ID.
   * First time session is established, we save the ID in localStorage with 10 days expiration.
   * Every time we establish a websocket connection, we pass that initial session ID as a parameter.
   * That way each subsequent session created in backend will contain a reference to initial original session ID.
   * This is necessary for proper sales tracking.
   *
   * @param sessionId
   * @private
   */
  private assignCreditedSessionId(sessionId: string) {
    if (localStorageAvailable) {
      let state = this.getGlobalState()

      if (!state.credited || state.credited.constructor !== Array) {
        state.credited = []
      }
      let host = new URL(this.location!!).host
      let match = state.credited.findIndex(p => p.host === host) ?? null

      if (
        match == -1 ||
        this.daysBetween(
          new Date(state.credited[match].timestamp),
          new Date(),
        ) >= 10
      ) {
        if (match != -1) {
          state.credited = state.credited.splice(match, 1)
        }
        state.credited.push({
          id: sessionId,
          host: host,
          timestamp: new Date().getTime(),
        })
        localStorage.setItem('contester_state', JSON.stringify(state))
      }
    }
  }

  private getCreditedSessionId(): string | null {
    if (localStorageAvailable) {
      let state = this.getGlobalState()
      let host = new URL(this.location!!).host
      if (state.credited && state.credited.constructor === Array) {
        let match = state.credited.find(p => p.host === host) ?? null
        if (
          match !== null &&
          this.daysBetween(new Date(match.timestamp), new Date()) < 10
        ) {
          return match.id
        }
      }
    }
    return null
  }

  init(
    experienceId: string | null,
    dispatch: any,
    history: any,
    widgetLocation: string,
  ): void {
    try {
      // pass on query params to ws creation to correctly establish session
      let queryString = window.location.search
      let wsPathWithParams: string
      if (queryString.length > 0) {
        wsPathWithParams =
          this.wsPath + queryString + `&experienceId=${experienceId}`
      } else {
        wsPathWithParams = this.wsPath + `?experienceId=${experienceId}`
      }

      this.location = widgetLocation
      // if credited session id is available, append it to ws connection query string
      let sessionId = this.getCreditedSessionId()
      if (sessionId != null) {
        wsPathWithParams += '&contester_sessionId=' + sessionId
      }

      this.dispatch = dispatch
      this.history = history

      if (this.socket == null && experienceId == null) {
        // do nothing
      }
      if (this.socket == null && experienceId != null) {
        // initialize for the first time
        this.socket = new WebSocket(wsPathWithParams)
        this.initSocket(this.socket)
        this.currentExperienceId = experienceId
      } else if (this.socket != null && experienceId == null) {
        // do nothing
      } else if (
        this.socket != null &&
        experienceId != null &&
        this.currentExperienceId != experienceId
      ) {
        // replace socket
        this.socket.close(1000)
        this.socket = new WebSocket(wsPathWithParams)
        this.currentExperienceId = experienceId
        this.initSocket(this.socket)
      }
    } catch (e) {
      console.log('error initializing websocket connection ' + e)
    }
  }
}

export const ws = new WS()
