import {
  SET_IS_LOADING,
  SET_FINAL_CARD_UUID,
  SLIDE_TO,
  SET_SP_ID,
  SET_INPUT_FORMS,
  SET_PRODUCTS,
  INCREMENT_LOAD_COUNT,
  SET_ITEMS,
  SET_DRAWER_INFO,
  SET_BRAND,
  SET_TOTAL_INTERACTIONS,
  SET_MEDIALINK,
  SET_CARD_UUID,
  ENABLE_CAROUSEL,
  ENABLE_REVIEW_MODE,
  SET_PARENT_CARD,
  SET_VIDEO_CURRENT_TIME,
  SET_ISSET_VIDEO_CURRENT_TIME,
  IS_WIDGET_MAXIMIZED,
  IS_WIDGET_EMBEDDED,
  IS_WIDGET_CUSTOM_NAV,
  TITLE_STYLE,
  SET_SP_HASH,
  SET_SEQUENCE_STATISTICS,
  SET_SEQUENCE_LIVE_DATA,
  SET_WIDGET_LOCATION,
} from '../../constants/actions'

const slide = payload => ({
  type: SLIDE_TO,
  ...payload,
})

const setIsLoading = payload => ({
  type: SET_IS_LOADING,
  ...payload,
})

const setFinalCard = payload => {
  return {
    type: SET_FINAL_CARD_UUID,
    ...payload,
  }
}

const setSpId = payload => ({
  type: SET_SP_ID,
  ...payload,
})

const setSpHash = payload => ({
  type: SET_SP_HASH,
  ...payload,
})

const setInputForms = payload => ({
  type: SET_INPUT_FORMS,
  ...payload,
})

const setProducts = payload => ({
  type: SET_PRODUCTS,
  ...payload,
})

const incrementLoadCount = payload => ({
  type: INCREMENT_LOAD_COUNT,
  ...payload,
})

const setItems = payload => ({
  type: SET_ITEMS,
  ...payload,
})

const setDrawerInfo = payload => ({
  type: SET_DRAWER_INFO,
  ...payload,
})
const setSequenceStatistics = payload => ({
  type: SET_SEQUENCE_STATISTICS,
  ...payload,
})

const setSequenceLiveData = payload => ({
  type: SET_SEQUENCE_LIVE_DATA,
  ...payload,
})

const setBrand = payload => ({
  type: SET_BRAND,
  ...payload,
})

const setTotalInteractions = payload => ({
  type: SET_TOTAL_INTERACTIONS,
  ...payload,
})

const setMediaLink = payload => ({
  type: SET_MEDIALINK,
  ...payload,
})

const setCurrentCard = payload => {
  return {
    type: SET_CARD_UUID,
    ...payload,
  }
}

const enableCarouselCreator = payload => {
  return {
    type: ENABLE_CAROUSEL,
    ...payload,
  }
}
const enableReviewMode = payload => {
  return {
    type: ENABLE_REVIEW_MODE,
    ...payload,
  }
}

const setParentCard = payload => {
  return {
    type: SET_PARENT_CARD,
    ...payload,
  }
}

const setVideoCurrentTime = payload => {
  return {
    type: SET_VIDEO_CURRENT_TIME,
    ...payload,
  }
}

const setIsSetVideoCurrentTime = payload => {
  return {
    type: SET_ISSET_VIDEO_CURRENT_TIME,
    ...payload,
  }
}

const setIsWidgetMaximized = payload => {
  return {
    type: IS_WIDGET_MAXIMIZED,
    ...payload,
  }
}

const setWidgetEmbedded = payload => {
  return {
    type: IS_WIDGET_EMBEDDED,
    ...payload,
  }
}

const setWidgetLocation = payload => {
  return {
    type: SET_WIDGET_LOCATION,
    ...payload,
  }
}

const setCustomNav = payload => {
  return {
    type: IS_WIDGET_CUSTOM_NAV,
    ...payload,
  }
}

const setTitleStyle = payload => {
  return {
    type: TITLE_STYLE,
    ...payload,
  }
}

export {
  setFinalCard,
  slide,
  setIsLoading,
  setSpId,
  setSpHash,
  setInputForms,
  setProducts,
  incrementLoadCount,
  setItems,
  setDrawerInfo,
  setBrand,
  setTotalInteractions,
  setMediaLink,
  setCurrentCard,
  enableCarouselCreator,
  enableReviewMode,
  setParentCard,
  setVideoCurrentTime,
  setIsSetVideoCurrentTime,
  setIsWidgetMaximized,
  setWidgetEmbedded,
  setCustomNav,
  setTitleStyle,
  setSequenceStatistics,
  setSequenceLiveData,
  setWidgetLocation,
}
