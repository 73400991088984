import { useStateValue } from '../state-provider/StateProvider'
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded'
import { useState } from 'react'

function ProductTag({ p, productTagRef, showArtifacts }) {
  const [{ api_url }] = useStateValue()
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div
      className={
        'product-tag ' +
        (isHovered ? 'isHovered ' : '') +
        (!showArtifacts ? 'hide' : 'show')
      }
      style={{ top: p.coordinates.y + '%', left: p.coordinates.x + '%' }}
    >
      <div className="product-circle"></div>
      <div
        className={'product-info '}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        ref={productTagRef}
        onClick={() =>
          (window.location.href =
            api_url + '/sequencepolls/productlink/' + p.productLink)
        }
      >
        <div className="product-name">{p?.title}</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <div className="product-price">
            {p?.price.amount} {p?.price.currency}
          </div>
          <ArrowForwardIosRoundedIcon fontSize="small" />
        </div>
      </div>
    </div>
  )
}

export default ProductTag
