import classnames from 'classnames'
import isEmpty from 'lodash'
import LinkIcon from '@material-ui/icons/Link'
import { asset } from '../../utils/utils'
import { useStateValue } from '../state-provider/StateProvider'
import { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import SliderNav from '../slider-nav/SliderNav'
import {
  setIsLoading,
  setFinalCard,
  setIsSetVideoCurrentTime,
} from '../app/App.action'
import './Footer.css'
import { GA_BackButton } from '../../events/ga'
import { localStorageAppendToTimestamps } from '../../utils/localStorage'

function FooterContainer() {
  const [
    {
      parentCardId,
      cardUuid,
      finalCardUuid,
      sequencePollUuid,
      enableCarousel,
      isReviewMode,
      showDescription,
      showVariantDrawer,
    },
    dispatch,
  ] = useStateValue()

  const [copyLinkText, setCopyLinkText] = useState('Copy link')
  const [showOptions, setShowOptions] = useState(false)
  const [openMenu, setOpenMenu] = useState(false)

  let history = useHistory()

  function copyToCliboard() {
    setShowOptions(!showOptions)
    setOpenMenu(!openMenu)

    navigator.clipboard.writeText(
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href,
    )
    setCopyLinkText('Copied!')
  }
  useEffect(() => {
    if (showOptions) setCopyLinkText('Copy link')
  }, [showOptions])

  function handleOptions() {
    setShowOptions(!showOptions)
    setOpenMenu(!openMenu)
  }

  function goBack() {
    GA_BackButton()
    dispatch(setIsLoading({ isLoading: false }))
    dispatch(setFinalCard({ cardUuid: null }))
    dispatch(setIsSetVideoCurrentTime({ isSetVideoCurrentTime: false }))
    let targetWindow = window.parent
    localStorageAppendToTimestamps(cardUuid)
    targetWindow?.postMessage(
      {
        prevUrl:
          '/' + sequencePollUuid + '/' + (parentCardId ? parentCardId : ''),
        type: 'contester-step',
      },
      '*',
    )
    history.push(
      '/' +
        sequencePollUuid +
        '/' +
        (parentCardId ? parentCardId : '') +
        window?.location?.search,
    )
  }

  const optionsRef = useRef(null)
  const optionsToggleRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target) &&
        !optionsToggleRef.current.contains(event.target)
      ) {
        setShowOptions(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [optionsRef])

  const [shareData] = useState({
    title: 'Contester',
    text: 'Sequence Poll',
    url:
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href,
  })
  const shareClick = async () => {
    setShowOptions(!showOptions)
    setOpenMenu(!openMenu)
    try {
      await navigator.share(shareData)
    } catch (err) {
      console.log('Error: ' + err)
    }
  }

  function resetSP() {
    setShowOptions(!showOptions)
    setOpenMenu(!openMenu)
    dispatch(setFinalCard({ cardUuid: null }))
    history.push('/' + sequencePollUuid + history?.location?.search)
  }
  return (
    <div id="sp-footer">
      {enableCarousel && finalCardUuid ? <SliderNav /> : null}
      <div className="footer-buttons">
        <div
          className={
            'options-mobile ' +
            ((showVariantDrawer || showDescription) && 'hide')
          }
        >
          <div
            className={classnames('menu-toggle footer-btn', {
              'has-video-and-back':
                isReviewMode && !showDescription && cardUuid,
            })}
            ref={optionsToggleRef}
            onClick={handleOptions}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="8"
              viewBox="0 0 47 8"
            >
              <g
                id="Grupo_876"
                data-name="Grupo 876"
                transform="translate(0.438 0.223)"
              >
                <g
                  id="Elipse_53"
                  data-name="Elipse 53"
                  transform="translate(-0.438 -0.223)"
                  fill="#514f4f"
                  stroke="#514f4f"
                  strokeWidth="4"
                >
                  <circle cx="4" cy="4" r="4" stroke="none" />
                  <circle cx="4" cy="4" r="2" fill="none" />
                </g>
                <g
                  id="Elipse_57"
                  data-name="Elipse 57"
                  transform="translate(19.562 -0.223)"
                  fill="#514f4f"
                  stroke="#514f4f"
                  strokeWidth="4"
                >
                  <circle cx="4" cy="4" r="4" stroke="none" />
                  <circle cx="4" cy="4" r="2" fill="none" />
                </g>
                <g
                  id="Elipse_58"
                  data-name="Elipse 58"
                  transform="translate(38.562 -0.223)"
                  fill="#514f4f"
                  stroke="#514f4f"
                  strokeWidth="4"
                >
                  <circle cx="4" cy="4" r="4" stroke="none" />
                  <circle cx="4" cy="4" r="2" fill="none" />
                </g>
              </g>
            </svg>
          </div>

          {showOptions && (
            <div className="options" ref={optionsRef}>
              <div className="option" onClick={resetSP}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="25"
                  viewBox="0 0 30.153 25.846"
                >
                  <path
                    id="Icon_material-settings-backup-restore"
                    data-name="Icon material-settings-backup-restore"
                    d="M20.1,17.423a2.872,2.872,0,1,0-2.872,2.872A2.88,2.88,0,0,0,20.1,17.423ZM17.23,4.5A12.924,12.924,0,0,0,4.308,17.423H0l5.743,5.743,5.743-5.743H7.179A10.058,10.058,0,1,1,11.4,25.607L9.362,27.675A12.923,12.923,0,1,0,17.23,4.5Z"
                    transform="translate(0 -4.5)"
                    fill="#514f4f"
                  />
                </svg>
              </div>
              <div className="option" onClick={copyToCliboard}>
                <LinkIcon style={{ color: '#514f4f' }} fontSize={'large'} />
              </div>
              <div className="option" onClick={shareClick}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.451"
                  height="25"
                  viewBox="0 0 22.451 24.846"
                >
                  <path
                    id="Icon_material-share"
                    data-name="Icon material-share"
                    d="M23.209,20.562a3.632,3.632,0,0,0-2.445.96l-8.893-5.176a4.083,4.083,0,0,0,.112-.873,4.083,4.083,0,0,0-.112-.873l8.793-5.126a3.733,3.733,0,1,0-1.2-2.732,4.083,4.083,0,0,0,.112.873l-8.793,5.126a3.742,3.742,0,1,0,0,5.463l8.881,5.189a3.519,3.519,0,0,0-.1.811,3.642,3.642,0,1,0,3.642-3.642Z"
                    transform="translate(-4.5 -3)"
                    fill="#514f4f"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>

        <div className="right-button testdeploy">
          {isReviewMode && !showDescription && parentCardId && (
            <button
              className={'footer-btn ' + (finalCardUuid && 'hide')}
              id="back-btn-mobile"
              onClick={goBack}
            >
              <img alt="Go Back" src={asset('BackButton.svg')} />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
export default FooterContainer
