import { SET_INDEXSCROLL, SET_SCROLLSNAPS } from '../../constants/actions'

const setIndexScroll = payload => ({
  type: SET_INDEXSCROLL,
  ...payload,
})

const setScrollsnaps = payload => ({
  type: SET_SCROLLSNAPS,
  ...payload,
})

export { setIndexScroll, setScrollsnaps }
