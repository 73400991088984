import { default as classnames, default as classNames } from 'classnames'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { localStorageAppendToTimestamps } from '../../utils/localStorage'
import { setIsLoading, setCurrentCard } from '../app/App.action'
import CartMobile from '../cart-mobile/CartMobile'
import { setCartDrawer } from '../content/Content.action'
import LoadingIndicator from '../loading-indicator/LoadingIndicator'
import OverlayButtons from '../overlay-buttons/OverlayButtons'
import { useStateValue } from '../state-provider/StateProvider'
import VariantDrawer from '../variant-drawer/VariantDrawer'
import { clearLoadCount, setTitle } from './photo-grid.action'
import './PhotoGrid.css'

export default function PhotoGrid() {
  const DIRECT_LINK = 'DIRECT_LINK'
  const [
    {
      items,
      isLoading,
      cardUuid,
      finalCardUuid,
      loadCount,
      header,
      showCartDrawer,
    },
    dispatch,
  ] = useStateValue()
  const [cachedItems, setCachedItems] = useState([])
  const [showArtifacts, setShowArtifacts] = useState(true)
  let history = useHistory()
  const [isSafari] = useState(
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
  )

  let [showFadeIn, setShowFadeIn] = useState(true)
  useEffect(() => {
    if (loadCount === items?.length) {
      setCachedItems(items)
      dispatch(clearLoadCount())
      dispatch(setIsLoading({ isLoading: false }))
      dispatch(setTitle({ title: items[0]?.title }))
    }
  }, [loadCount, dispatch, items])

  useEffect(() => {
    if (!finalCardUuid && showFadeIn) {
      setShowFadeIn(true)
    } else {
      setShowFadeIn(false)
    }
  }, [cardUuid, finalCardUuid, showFadeIn])

  function setCard(cardUuid, spId) {
    dispatch(setIsLoading({ isLoading: true }))
    let targetWindow = window.parent
    if (history?.location?.search?.split('inline=')?.[1] !== 'true') {
      targetWindow?.postMessage(
        {
          prevUrl: '/' + spId + '/' + cardUuid,
          type: 'contester-step',
        },
        '*',
      )
    } else {
      targetWindow?.postMessage(
        {
          prevUrl: '/' + spId + '/' + cardUuid + history?.location?.search,
          type: 'contester-step-inline',
        },
        '*',
      )
    }
    if (isLoading) return

    document.querySelector('.video-react-video')?.pause()
    if (!finalCardUuid) {
      history.push('/' + spId + '/' + cardUuid + history?.location?.search)
      dispatch(setCurrentCard({ cardUuid: cardUuid }))
    }
    dispatch(setIsLoading({ isLoading: false }))
  }
  // hardcoded value for possibility to control highlighting from contester panel
  const allowHighlight = false
  return (
    <>
      <div
        className={classnames(
          {
            'Split-One': cachedItems?.length === 1,
            'Split-Two': cachedItems?.length === 2,
            'Split-Three': cachedItems?.length === 3,
            'Split-Four': cachedItems?.length === 4,
            'Split-Five': cachedItems?.length === 5,
            'Split-Six': cachedItems?.length === 6,
            gridAmplify: finalCardUuid,
            'no-header': header == null,
            'is-loading': isLoading,
          },
          'Photo-Grid',
          'isSafari',
        )}
      >
        {finalCardUuid ? (
          <OverlayButtons
            showArtifacts={showArtifacts}
            items={cachedItems}
            setShowArtifacts={setShowArtifacts}
          />
        ) : null}
        <div
          id="gray-overlay"
          onClick={() => dispatch(setCartDrawer({ showCartDrawer: false }))}
          className={showCartDrawer ? 'show' : 'hide'}
        ></div>

        {(window.location.hostname === 'staging-s.contester.net' ||
          window.location.hostname === 'localhost') && (
          <>
            <CartMobile />
            {finalCardUuid && <VariantDrawer />}
          </>
        )}

        {/* Todo: Add CartMobile inside <Content /> to fix z-index issues */}

        {cachedItems?.map((item, index) => (
          <React.Fragment key={index}>
            <div
              className={classNames(`card-item card-${index + 1}`, {
                'is-highlighted': allowHighlight,
              })}
            >
              <img
                height={item.cardId === finalCardUuid ? '100%' : null}
                style={{
                  transform:
                    !showArtifacts && item.cardId === cardUuid && finalCardUuid
                      ? 'scale(1.07)'
                      : null,
                  animation: !isLoading ? 'fadeIn ease-in-out 200ms' : null,
                  zIndex:
                    finalCardUuid && item.cardId !== finalCardUuid ? -10 : 0,
                  objectPosition:
                    item.cardStyleParameters.objectPosition ?? '50% 50%',
                }}
                id={item.cardId}
                className={classnames(
                  { amplifyPic: item.cardId === finalCardUuid },
                  'grid-photo',
                  'isSafari',
                )}
                draggable="false"
                alt="Product Variant"
                onError={e => {
                  e.target.onerror = null
                  e.target.src = 'default.png'
                }}
                onClick={() => setCard(item.cardId)}
                pos={index + 1}
                src={item.image.src}
              />
              <LoadingIndicator
                directLink={DIRECT_LINK}
                finalCardUuid={finalCardUuid}
                item={item}
                setCard={() => setCard(item.cardId, item.sequencePollId)}
                pos={index + 1}
                cardUuid={cardUuid}
                show={item.cardId === cardUuid}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  )
}
