import { useEffect, useRef, useState } from 'react'
import { useStateValue } from '../state-provider/StateProvider'
import '../cart-mobile/CartMobile.css'
import './VariantDrawer.css'

import { asset } from '../../utils/utils'

import { CartProduct } from '../../types/index'
import { setCartDrawer } from '../content/Content.action'
import { addToCard, setVariantDrawer } from './variant-drawer.action'

const VariantDrawer = () => {
  const [
    {
      showCartDrawer,
      cart,
      showVariantDrawer,
      api_url,
      cardUuid,
      sequencePollUuid,
      products,
      items,
      finalCardUuid,
    },
    dispatch,
  ] = useStateValue()
  const cartDrawerRef = useRef<any>(null)

  useEffect(() => {
    close()
  }, [])

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        cartDrawerRef.current &&
        !cartDrawerRef.current?.contains(event.target) &&
        showCartDrawer
      ) {
        dispatch(setCartDrawer({ showCartDrawer: false }))
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [cartDrawerRef, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showCartDrawer) {
      close()
    }
  }, [showCartDrawer])

  const [variant, setVariant] = useState({
    id: '',
    size: '',
    color: '',
    stock: 0,
  })

  /*
  const [variantId, setVariantId] = useState<string>('');

  function setDefaultVariant() {
    console.log('set default is called');
    if (variant.id === '') {
      setVariant({
        id: products[0]?.variants[0].variantId,
        size: products[0]?.variants[0].size,
        color: products[0]?.variants[0].color,
        stock: products[0]?.variants[0].stock,
      });
    }
  }

  useEffect(() => {
    setDefaultVariant();
  }, [products]);
*/
  function addToCart() {
    let alreadyAdded: number = cart.findIndex(
      (p: CartProduct) =>
        p.variantId == variant.id ||
        p.variantId == products[0]?.variants[0].variantId,
    )
    if (alreadyAdded != -1) return

    setVariant({
      ...variant,
      id: products[0].variants.find(
        (v: any) => v.size == variant.size && v.color == variant.color,
      )?.variantId,
    })

    let new_product: CartProduct = {
      variantId: variant.id || products[0]?.variants[0].variantId,
      name: products[0]?.title,
      price: products[0]?.price,
      size: variant.size || products[0]?.variants[0].size,
      color: variant.color || products[0]?.variants[0].color,
      img_url: items.find((i: any) => i.cardId === finalCardUuid).image.src,
      max_stock: variant.stock || products[0]?.variants[0].stock,
      quantity: 1,
    }
    dispatch(addToCard({ new_product }))

    fetch(
      api_url + '/sequencepolls/' + sequencePollUuid + '/sessionParameter',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: 'ADD_ITEM_TO_CART',
          value: cardUuid,
        }),
      },
    )
      .then(res => res.json())
      .then(async result => {})
  }
  async function checkout() {
    await addToCart()
    fetch(
      api_url + '/sequencepolls/' + sequencePollUuid + '/sessionParameter',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: 'GO_TO_CART',
          value: cardUuid,
        }),
      },
    )
      .then(res => res.json())
      .then(async result => {
        window.location.href = result.redirectUrl
      })
  }

  async function btnContinue() {
    await addToCart()
    close()
  }

  function close() {
    dispatch(setVariantDrawer({ showVariantDrawer: false }))
  }

  const handleVariantChange = (e: any) => {
    setVariant({
      ...variant,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <div
      ref={cartDrawerRef}
      className={
        'cart-mobile-drawer  variant-drawer ' +
        (showVariantDrawer && finalCardUuid ? 'show' : 'hide')
      }
    >
      <img onClick={close} src={asset('cross.svg')} className="cancel"></img>
      <div className="product-row">
        <div className="primary name">{products[0]?.title}</div>
        <div className="primary price">
          {products[0]?.price.amount} {products[0]?.price.currency}
        </div>
      </div>

      <div className="variant-list">
        <div className="variant secondary">Color:</div>
        <select name="color" onChange={e => handleVariantChange(e)}>
          {products[0]?.variants.map((v: any, index: any) => (
            <option key={index} value={v.color}>
              {v.color}
            </option>
          ))}
        </select>

        <br></br>

        <div className="variant secondary">Size:</div>
        <select name="size" onChange={e => handleVariantChange(e)}>
          {products[0]?.variants.map((v: any, index: any) => (
            <option key={index} value={v.size}>
              {v.size}
            </option>
          ))}
        </select>

        <br></br>
      </div>
      <br></br>

      <div className="checkout variant">
        <button className="btnSecondary" onClick={btnContinue}>
          CONTINUE
        </button>
        <button onClick={checkout}>CHECK OUT</button>
      </div>
    </div>
  )
}

export default VariantDrawer
