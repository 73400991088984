export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_FINAL_CARD_UUID = 'SET_FINAL_CARD_UUID'
export const SLIDE_TO = 'SLIDE_TO'
export const SET_SP_ID = 'SET_SP_ID'
export const SET_SP_HASH = 'SET_SP_HASH'
export const SET_INPUT_FORMS = 'SET_INPUT_FORMS'
export const SET_PRODUCTS = 'SET_PRODUCTS'
export const INCREMENT_LOAD_COUNT = 'INCREMENT_LOAD_COUNT'
export const SET_ITEMS = 'SET_ITEMS'
export const SET_DRAWER_INFO = 'SET_DRAWER_INFO'
export const SET_BRAND = 'SET_BRAND'
export const SET_TOTAL_INTERACTIONS = 'SET_TOTAL_INTERACTIONS'
export const SET_MEDIALINK = 'SET_MEDIALINK'
export const CLEAR_LOAD_COUNT = 'CLEAR_LOAD_COUNT'
export const SET_TITLE = 'SET_TITLE'
export const SET_CART_DRAWER = 'SET_CART_DRAWER'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const ADD_TO_CART = 'ADD_TO_CART'
export const SET_SHOW_DESCRIPTION = 'SET_SHOW_DESCRIPTION'
export const SET_INDEXSCROLL = 'SET_INDEXSCROLL'
export const SET_SCROLLSNAPS = 'SET_SCROLLSNAPS'
export const SET_VARIANT_DRAWER = 'SET_VARIANT_DRAWER'
export const SET_CARD_UUID = 'SET_CARD_UUID'
export const SET_HEADER = 'SET_HEADER'
export const SET_PARENT_CARD = 'SET_PARENT_CARD'
export const SET_OVERLAY_CARDS = 'SET_OVERLAY_CARDS'
export const ENABLE_CAROUSEL = 'ENABLE_CAROUSEL'
export const ENABLE_REVIEW_MODE = 'ENABLE_REVIEW_MODE'
export const SET_VIDEO_CURRENT_TIME = 'SET_VIDEO_CURRENT_TIME'
export const SET_ISSET_VIDEO_CURRENT_TIME = 'SET_ISSET_VIDEO_CURRENT_TIME'
export const IS_WIDGET_MAXIMIZED = 'IS_WIDGET_MAXIMIZED'
export const IS_WIDGET_EMBEDDED = 'IS_WIDGET_EMBEDDED'
export const IS_WIDGET_CUSTOM_NAV = 'IS_WIDGET_CUSTOM_NAV'
export const TITLE_STYLE = 'TITLE_STYLE'
export const SET_SEQUENCE_STATISTICS = 'SET_SEQUENCE_STATISTICS'
export const SET_SEQUENCE_LIVE_DATA = 'SET_SEQUENCE_LIVE_DATA'
export const SET_WIDGET_LOCATION = 'SET_WIDGET_LOCATION'
