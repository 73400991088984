import { SET_CART_DRAWER, SET_SHOW_DESCRIPTION } from '../../constants/actions'

const setCartDrawer = payload => ({
  type: SET_CART_DRAWER,
  ...payload,
})

const setShowDescription = payload => ({
  type: SET_SHOW_DESCRIPTION,
  ...payload,
})

export { setCartDrawer, setShowDescription }
